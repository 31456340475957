module.exports = {
  title: '收付方式',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        label: '收付方式代碼',
        unique: true,
      },
      paymentMethod: {
        type: 'String',
        label: '收付方式',
      },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'paymentMethod',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          [{ path: 'name', span: 2 }],
          ['key'],
          ['paymentMethod'],
        ],
      },
    ],
  },
}
