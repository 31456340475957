const model = {
  title: '發票卷資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000566,
        label: '發票卷編號',
        type: 'String',
      },
      month: {
        ragicField: 1000561,
        label: '發票卷月份',
        type: 'String',
        input: 'select',
        options: ['0102', '0304', '0506', '0708', '0910', '1112'],
      },
      alphabet: {
        ragicField: 1000567,
        label: '發票卷英文',
        type: 'String',
      },
      start: {
        ragicField: 1000568,
        label: '發票卷起號',
        type: 'String',
      },
      end: {
        ragicField: 1000569,
        label: '發票卷迄號',
        type: 'String',
      },
      lastUsed: {
        ragicField: 1000570,
        label: '發票卷使用止號',
        type: 'String',
      },
      startTime: {
        ragicField: 1000571,
        label: '發票卷換卷時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      endTime: {
        ragicField: 1000597,
        label: '發票卷停用時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      machine: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '使用發票機台', reference: 'machines', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '使用發票機台', ragicField: 1000558 },
        },
      },
      updatedAt: {
        ragicField: 1001036,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}

const main = {
  title: '發票卷資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'month',
        'alphabet',
        'start',
        'end',
        'lastUsed',
        'startTime',
        'endTime',
        'machine.key',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['month'],
          ['alphabet'],
          ['start'],
          ['end'],
          ['lastUsed'],
          ['startTime'],
          ['endTime'],
          ['machine.id'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
