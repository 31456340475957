const mergeDeep = require('coact/utils/mergeDeep')

const serverSchema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId', autogeneratable: true },
    '@user': { type: 'ObjectId', reference: 'users' },
    '@group': { type: 'ObjectId', reference: 'groups' },
    '@groups': {
      type: 'Array',
      contents: [{ type: 'ObjectId', reference: 'groups' }],
    },
    '@@createdAt': { type: 'Date', required: true },
    '@@updatedAt': { type: 'Date', required: true },
  },
}


module.exports = function mergeSystemFields (schema) {
  return mergeDeep(serverSchema, schema)
}
