"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AssertionInfoStatus;
(function (AssertionInfoStatus) {
    AssertionInfoStatus[AssertionInfoStatus["Unknown"] = 0] = "Unknown";
    AssertionInfoStatus[AssertionInfoStatus["Created"] = 1] = "Created";
    AssertionInfoStatus[AssertionInfoStatus["Asserting"] = 2] = "Asserting";
    AssertionInfoStatus[AssertionInfoStatus["Submitted"] = 3] = "Submitted";
    AssertionInfoStatus[AssertionInfoStatus["Finished"] = 4] = "Finished";
    AssertionInfoStatus[AssertionInfoStatus["Cancelled"] = 5] = "Cancelled";
    AssertionInfoStatus[AssertionInfoStatus["Deleted"] = 6] = "Deleted";
})(AssertionInfoStatus = exports.AssertionInfoStatus || (exports.AssertionInfoStatus = {}));
