import compose from 'recompose/compose'

import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'

import querystring from 'query-string' // vender package

import View from './RagicSyncer'


const url = 'api/route/toRagicSyncer'
async function fetchModel (model, appendMessage) {
  let hasNext = true
  let fetchingCenter = false
  let skip = 0
  const limit = 100
  /* eslint-disable no-await-in-loop */
  while (hasNext) {
    const query = querystring.stringify({
      fetchFromRagic: null,
      limit,
      skip,
      model,
      fetchingCenter,
    })
    const response = await fetch(
      `${url}?${query}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
    const result = await response.json()
    appendMessage(result.results)
    hasNext = result.hasNext
    if (hasNext) skip += limit
    else if (hasNext !== true && fetchingCenter === true) {
      hasNext = true
      fetchingCenter = false
      skip = 0
    }
  }
  /* eslint-enable no-await-in-loop */
}

async function postModel (model, appendMessage) {
  let hasNext = true
  const limit = 5
  /* eslint-disable no-await-in-loop */
  while (hasNext) {
    const query = querystring.stringify({
      postToRagic: null,
      model,
      limit,
    })
    const response = await fetch(
      `${url}?${query}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
    const result = await response.json()
    appendMessage(result.results)
    hasNext = result.hasNext
  }
  /* eslint-enable no-await-in-loop */
}

export default compose(
  withState('result', 'setResult', []),
  withHandlers({
    syncToRagic: ({ setResult }) => async () => {
      function append (message) {
        setResult(r => r.concat(message))
      }

      append('同步鏡框顏色資料...')
      await postModel('typeAColors', append)
      // append('同步門市資料...')
      // await postModel('stores', append)
      append('同步供應商資料...')
      await postModel('suppliers', append)
      append('同步員工資料...')
      await postModel('staffers', append)
      append('同步品牌資料...')
      await postModel('brands', append)
      append('同步系列資料...')
      await postModel('serieses', append)
      append('同步商品資料...')
      await postModel('products', append)
      append('傳送完成')
    },
    fetchFromRagic: ({ setResult }) => async () => {
      function append (message) {
        setResult(r => r.concat(message))
      }
      append('抓取鏡框顏色資料...')
      await fetchModel('typeAColors', append)
      // append('抓取門市資料...')
      // await fetchModel('stores', append)
      append('抓取供應商資料...')
      await fetchModel('suppliers', append)
      append('抓取員工資料...')
      await fetchModel('staffers', append)
      append('抓取品牌資料...')
      await fetchModel('brands', append)
      append('抓取系列資料...')
      await fetchModel('serieses', append)
      append('抓取商品資料...')
      await fetchModel('products', append)
      append('抓取完成')
    },
  }),
)(View)
