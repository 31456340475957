const model = {
  title: '庫存資料',
  schema: {
    type: 'Object',
    contents: {
      key: { type: 'String', label: '庫存編號', disabled: true },
      productRef: { type: 'ObjectId', label: '庫存商品', reference: 'products', refKeys: ['key'] },
      warehousingRef: { type: 'ObjectId', label: '倉儲單位', reference: 'parties', refKeys: ['name'] },
      qty: { type: 'Number', label: '數量' },
    },
  },
  index: {
    key: {
      key: { key: 1, warehousingRef: 1, productRef: 1 },
    },
  },
}

const records = {
  title: '庫存明細',
  data: {
    bundle: {
      stockRecords: { path: ['records'], quantity: 'many' },
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['productRef'],
          ['warehousingRef'],
          ['qty'],
        ],
      },
      {
        name: 'tableEditor',
        path: 'records',
        exclude: ['stockRef'],
        // fields: ['name', 'qty'],
      },
    ],
    populationdepth: 2,
  },
  export: {
    fields: [
      'key',
      'productRef.name',
      { path: 'warehousingRef.name', label: '地點' },
      'qty',
      'productRef.key',
      'productRef.centerCost',
      'productRef.storeCost',
      'productRef.type',
      'productRef.brandRef.key',
      'productRef.supplierRef.name',
    ],
    populations: '*',
    populationdepth: 3,
  },
}

module.exports = {
  ...model,
  views: {
    records,
  },
}
