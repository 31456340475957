const model = {
  title: '客戶資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000508,
        label: '客戶編號',
        type: 'String',
        unique: true,
      },
      name: {
        ragicField: 1000509,
        label: '客戶姓名',
        type: 'String',
      },
      gender: {
        ragicField: 1000516,
        label: '客戶性別',
        type: 'String',
        input: 'select',
        options: ['男', '女'],
      },
      birthday: {
        ragicField: 1000511,
        label: '客戶生日',
        type: 'String',
      },
      mobile: {
        ragicField: 1000512,
        label: '客戶手機',
        type: 'String',
      },
      phone: {
        ragicField: 1000510,
        label: '客戶電話',
        type: 'String',
      },
      secPhone: {
        ragicField: 1000514,
        label: '客戶備用電話',
        type: 'String',
      },
      address: {
        ragicField: 1000519,
        label: '客戶地址',
        type: 'String',
      },
      note: {
        ragicField: 1000520,
        label: '客戶備註',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001038,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      // retailOrderRef: {
      //   type: 'ObjectId',
      //   reference: 'retailOrders',
      //   refKeys: ['key'],
      //   input: 'select',
      //   label: '承件單號',
      //   ragicField: 1000522,
      // },
    },
    subtables: [
      {
        modelName: 'retailOrders',
        ragicField: 1000522,
        refFieldName: 'custNameRef',
      },
      {
        modelName: 'prescripts',
        ragicField: 1000532,
        refFieldName: 'custRef',
      },
    ],
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}

const main = {
  title: '基本客戶資料',
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'gender',
        'birthday',
        'mobile',
        'phone',
        'secPhone',
        'note',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['name'],
          ['gender'],
          ['birthday'],
          ['mobile'],
          ['phone'],
          ['secPhone'],
          ['address'],
          ['note'],
        ],
      },
    ],
  },
}

const buildBundlePair = (bundleModel) => {
  const bundlePair = {
    bundle: { },
    editor: {
      title: bundleModel,
      name: 'tableEditor',
      path: bundleModel,
      exclude: ['custNameRef'],
    },
  }
  bundlePair.bundle[bundleModel] = { path: [bundleModel], quantity: 'many' }
  return bundlePair
}

const ui = bundleModel => [
  {
    name: 'formEditor',
    fields: [
      ['key'],
      ['name'],
      ['gender'],
      ['birthday'],
      ['mobile'],
      ['phone'],
      ['secPhone'],
      ['address'],
      ['note'],
    ],
  },
  buildBundlePair(bundleModel).editor,
]

const retailOrders = {
  title: '客戶承件單管理',
  data: {
    bundle: buildBundlePair('retailOrders').bundle,
  },
  edit: { ui: ui('retailOrders') },
}

const prescripts = {
  title: '客戶處方管理',
  data: {
    bundle: buildBundlePair('prescripts').bundle,
  },
  edit: { ui: ui('prescripts') },
}

module.exports = {
  ...model,
  views: {
    main,
    retailOrders,
    prescripts,
  },
}
