const { reduce, path } = require('coact/ramda')
const hasPath = require('coact/utils/hasPath')

const mergeConfig = require('coact/config/mergeConfig')
const tradingFormModule = require('coact/modules/tradingForm/main')
const stockRecorderModule = require('coact/modules/stockRecorder/main')

const purchaseReturnsOrderSpec = {
  db: {
    orders: 'purchaseReturns',
    items: 'purchaseReturnItems',
    buyers: 'parties',
    sellers: 'parties',
    skus: 'products',
  },
  project: {
    skus: ['key'],
    buyers: ['name'],
    sellers: ['name'],
  },
  orderNo: { prefix: 'IR', format: 'YYYYMMDD', digits: 3 },
  productReader: {
    getQueryCode: code => code.split('#')[0],
    moveToFirst: true,
  },
}

const changeStockSpec = {
  db: {
    stocks: 'stocks',
    records: 'stockRecords',
    orders: 'purchaseReturns',
    items: 'purchaseReturnItems',
  },
  payload: {
    stock: {
      src: ({ doc, item }) => {
        if (doc.status === '新增') return null
        if (doc.status === '取消') return null
        if (doc.repairOrder === true) return null
        if (!hasPath(['skuRef'], item)) return null
        if (!(path(['skuRef', 'key'], item))) return null
        if (!hasPath(['sellerRef'], doc)) return null
        if (doc.sellerRef.storageMA === '不執行') return null
        return {
          key: `${doc.sellerRef.key}-${item.skuRef.key}`,
          warehousingRef: doc.sellerRef._id,
          productRef: item.skuRef._id,
        }
      },
      des: ({ doc, item }) => {
        if (doc.status === '新增') return null
        if (doc.status === '取消') return null
        if (doc.repairOrder === true) return null
        if (!hasPath(['skuRef'], item)) return null
        if (!(path(['skuRef', 'key'], item))) return null
        if (!hasPath(['buyerRef'], doc)) return null
        if (doc.buyerRef.storageMA === '不執行') return null
        return {
          key: `${doc.buyerRef.key}-${item.skuRef.key}`,
          warehousingRef: doc.buyerRef._id,
          productRef: item.skuRef._id,
        }
      },
    },
    record: {
      src: ({ doc, item }) => {
        if (!item) return null
        const date = doc.warehousingDate ? doc.warehousingDate : doc.date
        return { qty: item.qty, date }
      },
      des: ({ doc, item }) => {
        if (!item) return null
        const date = doc.warehousingDate ? doc.warehousingDate : doc.date
        return { qty: -item.qty, price: item.price, date }
      },
    },
  },
  refPath: {
    item: {
      record: 'stockRecordRefs',
    },
    record: {
      stock: 'stockRef',
      item: 'srcDBRef',
    },
  },
}

const mainView = {
  title: '進貨退回單管理',
  data: {
    bundle: {
      payments: { },
    },
  },
  edit: {
    noclone: {
      statementType: true,
      buyer: true,
      buyerStockAddress: true,
      seller: true,
      sellerStockAddress: true,
      status: true,
      payDate: true,
      payStatus: true,
      note: true,
      sysNote: true,
      agentKey: true,
    },
    autofill: {
      '': [
        {
          src: 'buyerRef',
          fill: buyer => ({
            buyer,
            buyerStockAddress: buyer.address,
          }),
        },
        {
          src: 'sellerRef',
          fill: seller => ({
            seller,
            sellerStockAddress: seller.address,
          }),
        },
        {
          src: 'payStatus',
          fill: payStatus => ({
            payStatus,
            payDate: null,
          }),
        },
      ],
      'items.0': {
        src: 'skuRef',
        fill: (sku) => {
          if (sku) {
            const fillResult = {
              sku,
              key: sku.key,
              name: sku.name,
              bookPrice: sku.centerCost,
              price: sku.centerCost,
              salePrice: sku.salePrice,
              unit: sku.unit,
              supplier: sku.supplierRef,
            }
            return fillResult
          }
          return { sku: null }
        },
      },
    },
    computer: {
      '': [
        (value) => {
          const amount = reduce(
            (total, { qty, price }) => (
              total + (-qty * price)
            ),
            0,
            value.items || [],
          )

          const { priceType, taxRate, discount } = value
          const { date, payDate, sellerRef, buyerRef, status } = value
          const afterTax = (priceType === '未稅價') ? Math.round(amount * taxRate) : Math.round(amount)
          const receivable = afterTax - discount
          const price = Math.round(receivable / taxRate)
          const tax = receivable - price
          const items = []
          // generate nums String
          const counterObj = { }
          if (value.items) {
            value.items.forEach((element) => {
              const { unit, qty } = element
              const item = element
              item.date = date
              item.payDate = payDate
              item.sellerRef = sellerRef
              item.seller = path(['name'], sellerRef)
              item.buyerRef = buyerRef
              item.buyer = path(['name'], buyerRef)
              item.status = status
              item.statementType = '進貨退回單'
              items.push(item)
              if (unit) {
                if (counterObj[unit]) counterObj[unit] += qty
                else counterObj[unit] = qty
              } else if (counterObj['個']) counterObj['個'] += qty
              else counterObj['個'] = qty
            })
          }
          let summary = '共:'
          Object.entries(counterObj).forEach(([unit, num]) => {
            summary += (num) && ` ${num.toString()} ${unit}`
          })

          return {
            amount,
            tax,
            price,
            afterTax,
            receivable,
            summary,
            items,
          }
        },
      ],
      'items.0': [
        ({ qty, price }) => {
          if (Number.isNaN(qty * price)) return { subtotal: null }
          return { subtotal: -qty * price }
        },
      ],
    },
    creator: {
      statementType: 'purchaseReturn',
      payStatus: '正常',
      taxRate: 1.05,
      priceType: '未稅價',
      payDate: '$$NOW',
      printStatus: '未列印',
    },
    loaders: {
      'purchaseReturns:main': purchaseReturn => ({
        items: purchaseReturn.items,
        sellerRef: purchaseReturn.sellerRef,
        buyerRef: null,
      }),
    },
    actions: {
      print: {
        text: '列印',
        hotKey: 'ctrl+p',
        effect: { name: 'saveAndPrint' },
      },
      arrive: {
        text: '到貨',
        effect: { name: 'arrive' },
      },
      downloadItems: {
        text: '下載',
        effect: { name: 'downloadItems' },
      },
      transferTradeform: {
        text: '轉進貨單',
        action: 'saveAndCreate',
        effect: { name: 'saveAndCreate', spec: { copy: true, to: 'purchases:main' } },
      },
      reloadItems: {
        text: '更新價格',
        effect: { name: 'reloadItems' },
      },
    },
    effect: {
      saveAndPrint: (s, ctx) => {
        const printStatus = path(['doc', 'printStatus'], ctx)
        if (printStatus !== '已列印') {
          ctx.editPath({ path: ['printStatus'], value: '已列印' })
          ctx.saveDoc()
          window.print()
        } else {
          ctx.saveDoc()
          window.print()
        }
      },
      arrive: (s, ctx) => {
        ctx.editPath({ path: ['warehousingDate'], value: new Date() })
        ctx.editPath({ path: ['status'], value: '到貨' })
        ctx.saveDoc()
      },
      downloadItems: (s, ctx) => {
        const tradeKey = path(['doc', 'key'], ctx)
        const query = { orderRef: { $in: [`ObjectId(${path(['doc', '_id'], ctx)})`] } }
        const url = `/api/stream/data?feature=purchaseReturnItems&filename=${tradeKey}&limit=50&populationdepth=1&populations=%2A&query=${JSON.stringify(query)}&skip=0&sort={"@@createdAt":-1}&type=xlsx`
        window.open(url)
      },
      reloadItems: (s, ctx) => {
        const items = path(['doc', 'items'], ctx)
        if (items.length > 0) {
          let i = 0
          items.forEach((item) => {
            if (item.key !== null && item.key !== '' && item.key !== undefined) {
              ctx.editPath({ path: ['items', i, 'price'], value: item.skuRef.centerCost })
              ctx.editPath({ path: ['items', i, 'bookPrice'], value: item.skuRef.centerCost })
              // ctx.resetPath({ path: ['items', i], value: item })
            }
            i += 1
          })
        }
        ctx.saveDoc()
      },
    },
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'payDate', 'status', 'payStatus', 'repairOrder'], // 'create',
          ['buyerRef', 'buyer.phone', 'buyer.FAX', 'buyer.CID', 'printStatus'],
          [{ path: 'buyer.address', c: 1, span: 2 }, { path: 'buyerStockAddress', c: 1, span: 2 }],
          ['sellerRef', 'seller.phone', 'seller.FAX', 'seller.CID', 'warehousingDate'],
          [{ path: 'seller.address', c: 1, span: 2 }, { path: 'sellerStockAddress', c: 1, span: 2 }],
          ['amount', 'discount', 'priceType', 'taxRate', 'receivable'],
          ['agentKey', 'price', 'tax', 'afterTax'],
          ['cost', 'profit', 'summary', { path: 'note', span: 1 }, { path: 'sysNote', span: 1 }],
        ],
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: ['skuRef', 'qty', 'name', 'unit', 'bookPrice', 'price', 'subtotal', 'supplier', 'sysNote', 'note'],
        deletable: true,
      },
      payments: {
        title: '款項',
        name: 'tableEditor',
        path: 'paymentRefs',
        exclude: ['srcDBRef'],
        appendEmpty: false,
      },
    },
    populationdepth: 2,
  },
  list: {
    actions: {
      exportText: {
        text: '文字',
        effect: { run: 'first', name: 'exportData', type: 'csv' },
        // props: highEmphasis,
        icon: 'CloudDownload',
        authChecker: (viewAuth, userAuth) => (
          path(['authority', 'streams', 'data', 'get'], userAuth)
        ),
      },
    },
    ui: {
      fields: [
        'key',
        'status',
        'agentKey',
        'sellerRef',
        'buyerRef',
        'date',
        'payDate',
        'summary',
        'receivable',
        'tax',
        'price',
        'note',
        'payStatus',
        'warehousingDate',
      ],
    },
    populations: 'parties',
  },
}


const models = {
  purchaseReturns: {
    title: '進貨退回單管理',
    schema: {
      type: 'Object',
      contents: {
        ragicField: {
          label: 'Ragic Id',
          type: 'Number',
          ragicField: '_ragicId',
          disabled: true,
        },
        key: {
          label: '進退單號',
          disabled: true,
        },
        statementType: {
          type: 'String',
          label: '單據類型',
          disabled: true,
        },
        repairOrder: {
          type: 'Boolean',
          label: '維修單',
        },
        status: {
          label: '狀態',
          options: ['新增', '到貨', '取消'],
        },
        printStatus: {
          type: 'String',
          label: '列印狀態',
          disabled: true,
          options: ['未列印', '已列印'],
        },
        agentKey: {
          type: 'String',
          label: '廠商銷貨退回單號',
        },
        // isMerge
        date: {
          label: '進退日期',
          format: 'YYYY/MM/DD',
        },
        warehousingDate: {
          type: 'Date',
          label: '入庫日期',
          format: 'YYYY/MM/DD',
        },
        payDate: {
          type: 'Date',
          label: '結帳日期',
          format: 'YYYY/MM/DD',
        },
        create: {
          label: '建立單位',
          type: 'ObjectId',
          reference: 'stores',
          refKeys: ['name'],
          input: 'select',
        },
        payStatus: {
          type: 'String',
          label: '帳款狀態',
          input: 'select',
          options: ['正常', '延後處理'],
        },
        buyerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '退貨單位',
          initFocus: true,
        },
        buyer: {
          contents: {
            phone: {
              type: 'String',
              label: '電話',
              disabled: true,
            },
            FAX: {
              type: 'String',
              label: '傳真',
              disabled: true,
            },
            address: {
              type: 'String',
              label: '地址',
              disabled: true,
            },
            CID: {
              type: 'String',
              label: '退貨單位統編',
              disabled: true,
            },
          },
        },
        buyerStockAddress: {
          type: 'String',
          label: '收貨地址',
          disabled: true,
        },
        sellerRef: {
          label: '供貨單位',
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
        },
        seller: {
          contents: {
            phone: {
              label: '供貨電話',
              type: 'String',
              disabled: true,
            },
            FAX: {
              label: '供貨傳真',
              type: 'String',
              disabled: true,
            },
            address: {
              label: '供貨連絡地址',
              type: 'String',
              disabled: true,
            },
            CID: {
              label: '供貨單位統編',
              type: 'String',
              disabled: true,
            },
          },
        },
        sellerStockAddress: {
          label: '送貨地址',
          type: 'String',
          disabled: true,
        },
        amount: {
          label: '合計金額',
          type: 'Number',
          disabled: true,
        },
        taxRate: {
          label: '稅率',
          type: 'Number',
        },
        priceType: {
          label: '價格別',
          type: 'String',
          input: 'select',
          options: ['未稅價', '含稅價'],
        },
        afterTax: {
          label: '含稅金額',
          type: 'Number',
          disabled: true,
        },
        discount: {
          label: '折讓金額',
          type: 'Number',
        },
        receivable: {
          label: '應付金額',
          type: 'Number',
          disabled: true,
        },
        price: {
          label: '商品金額',
          type: 'Number',
          disabled: true,
        },
        tax: {
          label: '稅金',
          type: 'Number',
          disabled: true,
        },
        note: {
          label: '附註',
          type: 'String',
        },
        sysNote: {
          label: '系統備註',
          type: 'String',
        },
        summary: {
          label: '商品總數',
          disabled: true,
          type: 'String',
        },
        outStatus: {
          label: '出庫狀態',
          type: 'String',
        },
        inStatus: {
          label: '入庫狀態',
          type: 'String',
        },
        outType: {
          label: '出庫別',
          type: 'String',
        },
        inType: {
          label: '入庫別',
          type: 'String',
        },
        cost: {
          label: '商品成本',
          type: 'Number',
          disabled: true,
        },
        profit: {
          label: '利潤',
          type: 'Number',
          disabled: true,
        },
        paymentRefs: {
          type: 'Array',
          label: '款項',
          contents: [{ type: 'ObjectId', reference: 'payments' }],
        },
      },
    },
    views: {
      main: mainView,
    },
    import: {
      main: {
        search: { rebuildRef: null },
      },
    },
  },
  purchaseReturnItems: {
    title: '退貨項目',
    schema: {
      contents: {
        skuRef: {
          type: 'ObjectId',
          label: '貨品條碼',
          reference: 'products',
          refKeys: ['key'],
          input: 'select',
          width: 200,
        },
        key: { type: 'String', label: '貨品條碼', width: 200 },
        unit: { type: 'String', label: '單位', width: 60 },
        bookPrice: { disabled: true, type: 'Number', label: '單價', width: 60 },
        price: { type: 'Number', label: '計價', width: 60 },
        salePrice: { type: 'Number', label: '外價', width: 60 },
        supplier: {
          type: 'ObjectId',
          reference: 'suppliers',
          refKeys: ['name'],
          label: '供應商',
          width: 100,
        },
        sysNote: {
          label: '系統備註',
          type: 'String',
          width: 150,
        },
        stockRecordRefs: {
          type: 'Array',
          contents: [{
            type: 'ObjectId',
            label: '修改庫存紀錄',
            reference: 'stockRecords',
            refKeys: ['stockRef'],
          }],
        },
        statementType: {
          type: 'String',
          label: '單據類型',
          disabled: true,
        },
        sellerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '銷貨單位',
          disabled: true,
        },
        buyerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '進貨單位',
          disabled: true,
        },
        seller: {
          type: 'String',
          label: '銷貨單位',
        },
        buyer: {
          type: 'String',
          label: '進貨單位',
        },
        date: {
          type: 'Date',
          label: '進貨退回日期',
          format: 'YYYY/MM/DD',
          disabled: true,
        },
        payDate: {
          type: 'Date',
          label: '結帳日期',
          format: 'YYYY/MM/DD',
          disabled: true,
        },
        status: {
          type: 'String',
          label: '狀態',
          options: ['新增', '到貨', '取消'],
          disabled: true,
        },
      },
    },
    list: {
      actions: {
        exportText: {
          text: '文字',
          effect: { run: 'first', name: 'exportData', type: 'csv' },
          // props: highEmphasis,
          icon: 'CloudDownload',
          authChecker: (viewAuth, userAuth) => (
            path(['authority', 'streams', 'data', 'get'], userAuth)
          ),
        },
      },
      ui: {
        fields: ['key', 'name', 'qty', 'subtotal', 'supplier', 'sellerRef', 'buyerRef', 'date', 'payDate', 'status', 'sysNote', 'note'],
      },
    },
    edit: {
      ui: [
        {
          name: 'formEditor',
          cols: { default: 150, 1: 300 },
          fields: [
            ['skuRef'],
            ['key', 'name'],
            ['qty', 'unit'],
            ['bookPrice', 'price', 'salePrice'],
            ['supplier'],
            ['sysNote'],
          ],
        },
      ],
    },
    export: {
      fields: [
        'key',
        'name',
        'qty',
        'salePrice',
        'subtotal',
        'salePrice',
        'supplier.name',
        'orderRef.key',
        'statementType',
        'seller',
        'buyer',
        'date',
        'payDate',
        'status',
        'orderRef.taxRate',
        'orderRef.priceType',
        'note',
      ],
      populations: '*',
      populationdepth: 2,
    },
  },
}

const fullModels = reduce(
  mergeConfig,
  { },
  [
    tradingFormModule(purchaseReturnsOrderSpec),
    stockRecorderModule(changeStockSpec),
    { models },
  ],
)

module.exports = fullModels
