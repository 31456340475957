const { path } = require('coact/ramda')

module.exports = {
  title: '庫存記錄',
  schema: {
    type: 'Object',
    contents: {
      stockRef: {
        type: 'ObjectId',
        label: '所屬庫存',
        reference: 'stocks',
        refKeys: ['productRef'],
        formatter: (doc) => {
          const product = path(['productRef', 'key'], doc)
          const warehousing = path(['warehousingRef', 'name'], doc)
          return `${product}-${warehousing}`
        },
        width: 200,
      },
      date: { type: 'Date', label: '日期', format: 'YYYY/MM/DD' },
      srcDBRef: {
        type: 'DBRef',
        label: '記錄來源',
        formatter: (doc) => {
          if (!doc) return ''
          const key = path(['$id', 'orderRef', 'key'], doc)
          return `${key}`
        },
      },
      qty: { type: 'Number', label: '數量', width: 50 },
      note: { type: 'String', label: '內容' },
    },
  },
  index: {
    stockRef: {
      key: { stockRef: 1 },
    },
  },
  list: {
    ui: {
      fields: [
        'stockRef',
        'date',
        'srcDBRef',
        'qty',
        'note',
      ],
    },
    populations: '*',
    populationdepth: 2,
  },
  edit: {
    populationdepth: 2,
  },
}
