const mergeDeep = require('coact/utils/mergeDeep')

const { storeStatements } = require('../../models')
const tradingSchema = require('../../tradingData/schema')


const tradingsField = {
  contents: {
    tradings: {
      type: 'Array',
      contents: [tradingSchema],
    },
  },
}

const schema = mergeDeep(
  storeStatements.schema,
  tradingsField,
)

module.exports = schema
