import React from 'react'
import Button from '@material-ui/core/Button'

import { func, array } from 'prop-types'

function RagicSyncer ({ syncToRagic, result }) {
  return (
    <div>
      <br />
      <Button variant="contained" onClick={syncToRagic}>同步至線上伺服器</Button>
      <div>
        {result.map((row, index) => (
          <div key={index}>{row}</div>
        ))}
      </div>
    </div>
  )
}
RagicSyncer.propTypes = {
  syncToRagic: func,
  result: array,
}

export default RagicSyncer
