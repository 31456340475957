const model = {
  title: '門市資料',
  schema: {
    // name: '門市資料',
    type: 'Object',
    contents: {
      ragicField: {
        type: 'Number',
        ragicField: '_ragicId',
        label: 'RagicId',
        disable: true,
      },
      key: {
        type: 'String',
        label: '門市代碼',
        ragicField: '1000070',
        unique: true,
      },
      name: {
        type: 'String',
        ragicField: '1000001',
        label: '名稱',
      },
      organize: {
        type: 'String',
        ragicField: '1000476',
        label: '組織類別',
      },
      fullName: {
        type: 'String',
        ragicField: '1000135',
        label: '門市公司名',
      },
      storeName: {
        type: 'String',
        ragicField: '1000002',
        label: '門市店名',
      },
      owner: {
        type: 'String',
        ragicField: '1000009',
        label: '負責人',
      },
      profitEnterpriseKey: {
        type: 'String',
        ragicField: '1000189',
        label: '營業登記號碼',
      },
      CID: {
        type: 'String',
        ragicField: '1000005',
        label: '統一編號',
      },
      pharmKey: {
        type: 'String',
        ragicField: '1000190',
        label: '藥商許可證號碼',
      },
      phone: {
        type: 'String',
        ragicField: '1000006',
        label: '門市電話',
      },
      phone2: {
        type: 'String',
        ragicField: '1000134',
        label: '備用電話',
      },
      fax: {
        type: 'String',
        ragicField: '1000007',
        label: '門市傳真',
      },
      address: {
        type: 'String',
        ragicField: '1000008',
        label: '門市地址',
      },
      storageMA: {
        type: 'String',
        ragicField: '1000472',
        label: '庫存管理',
        options: ['執行', '不執行'],
        input: 'select',
      },
      manager: {
        type: 'String',
        ragicField: '1000184',
        label: '店長',
      },
      isOpen: {
        type: 'String',
        ragicField: '1000493',
        label: '是否營業',
        options: ['是', '否'],
        input: 'select',
      },
      openDate: {
        type: 'Date',
        ragicField: '1000011',
        label: '開幕日期',
      },
      type: {
        type: 'String',
        ragicField: '1000004',
        label: '門市類別',
      },
      closeDate: {
        type: 'Date',
        ragicField: '1000012',
        label: '閉店日期',
      },
      level: {
        type: 'String',
        ragicField: '1000003',
        label: '門市級別',
      },
      note: {
        type: 'String',
        ragicField: '1000017',
        label: '備註說明',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}


const main = {
  title: '門市資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'owner',
        'CID',
        'phone',
        'isOpen',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['name'],
          ['fullName'],
          ['storeName'],
          ['owner'],
          ['profitEnterpriseKey'],
          ['CID'],
          ['pharmKey'],
          ['phone'],
          ['phone2'],
          ['fax'],
          ['address'],
          ['storageMA'],
          ['manager'],
          ['isOpen'],
          ['openDate'],
          ['type'],
          ['closeDate'],
          ['level'],
          ['note'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
