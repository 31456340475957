module.exports = {
  type: 'Object',
  contents: {
    src: { type: 'DBRef', label: '系統單號' },
    sellerSalesKey: { type: 'String', label: '供應商單號' },
    date: { type: 'Date', label: '日期' },
    payDate: { type: 'Date', label: '結帳日期' },
    content: { type: 'String', label: '內容' },
    status: { type: 'String', label: '狀態' },
    amount: { type: 'Number', label: '金額' },
  },
}
