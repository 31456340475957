import React from 'react'

class TemplateUploader extends React.Component {
  constructor (props) {
    super(props)
    this.uploadFile = this.uploadFile.bind(this)
  }

  uploadFile () {
    fetch(
      `api/print/${this.select.value}/template/xlsx`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: this.fileInput.files[0],
      },
    )
      .then(response => (response.ok ? response : Promise.reject(response)))
      .then(() => {
        // eslint-disable-next-line no-alert
        window.alert('upload done')
      }).catch((response) => {
        // eslint-disable-next-line no-alert
        window.alert('upload error', response)
      })
  }

  render () {
    return (
      <div>
        <select
          ref={(ref) => { this.select = ref }}
        >
          <option value=""> ---- choose ---- </option>
          <option value="storeStatement">門市應付對帳單</option>
          <option value="retailSaleReturnForm">門市銷貨退回單</option>
        </select>
        <br />
        <input
          ref={(ref) => { this.fileInput = ref }}
          type="file"
          id="upload_input"
          onChange={this.uploadFile}
        />
        <button
          type="button"
          onClick={() => window.open(`/api/print/${this.select.value}/template/xlsx`, '_blank')}
        >
          下載
        </button>
      </div>
    )
  }
}

export default TemplateUploader
