const moment = require('moment')
const R = require('ramda')

const prefix = 'RS'
const format = 'YYYYMM'
const digits = 3

const model = {
  title: '應收對帳單',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        label: '單號',
        counter: {
          key: () => `${prefix}${moment().format(format)}`,
          value: (key, value) => `${key}-${value.toString().padStart(digits, 0)}`,
        },
        disabled: true,
      },
      date: { type: 'Date', label: '日期', creator: '$$TODAY' },
      start: { type: 'Date', label: '開始日期' },
      end: { type: 'Date', label: '結束日期' },
      status: {
        type: 'String',
        label: '狀態',
        creator: '新增',
        options: ['新增', '確認', '對帳中', '已結'],
      },
      sumOfPayments: { type: 'Number', label: '應收金額', disabled: true },
      allowance: { type: 'Number', label: '折讓' },
      amount: { type: 'Number', label: '總計', disabled: true },
      note: { type: 'String', label: '備註' },
      receiverRef: { type: 'ObjectId', label: '應收單位', reference: 'parties', refKeys: ['name'] },
      payerRef: { type: 'ObjectId', label: '客戶', reference: 'parties', refKeys: ['name'] },
      name: { label: '客戶名稱', type: 'String' },
      phone: { label: '電話', type: 'String' },
      address: { label: '地址', type: 'String' },
      taxId: { label: '統編', type: 'String' },
      paymentRefs: {
        type: 'Array',
        contents: [{ type: 'ObjectId', reference: 'payments' }],
      },
    },
  },
}

const view = {
  title: '應收對帳單',
  list: {
    ui: {
      fields: [
        'key', 'status', 'receiverRef', 'payerRef', 'amount',
        'start', 'end', 'note',
      ],
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key', 'status', 'date'],
          ['receiverRef'],
          ['payerRef', 'phone', 'taxId', { span: 2, path: 'address' }],
          ['start', { c: 2, path: 'sumOfPayments' }],
          ['end', { c: 2, path: 'allowance' }],
          [{ span: 2, path: 'note' }, { c: 2, path: 'amount' }],
        ],
      },
      payments: {
        title: '明細',
        name: 'tableEditor',
        path: ['paymentRefs'],
        exclude: ['statementRef'],
        appendEmpty: false,
      },
    },
    populationdepth: 2,
    autofill: {
      '': {
        src: 'payerRef',
        fill: [
          { from: 'name', to: 'name' },
          { from: 'phone', to: 'phone' },
          { from: 'address', to: 'address' },
          { from: 'CID', to: 'taxId' },
        ],
      },
    },
    actions: {
      confirm: {
        text: '確認',
        effect: { name: 'changeStatus', status: '確認' },
        props: { variant: 'contained', color: 'primary' },
      },
      request: {
        text: '請款',
        effect: { name: 'changeStatementAndRecsStatus', status: '對帳中' },
        props: { variant: 'contained', color: 'primary' },
      },
      receive: {
        text: '收款',
        effect: { name: 'changeStatementAndRecsStatus', status: '已結' },
        props: { variant: 'contained', color: 'primary' },
      },
      print: {
        text: '列印',
        hotKey: 'ctrl+p',
        effect: { name: 'print' },
      },
    },
    effect: {
      changeStatus: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: s.status })
        ctx.saveDoc()
      },
      changeStatementAndRecsStatus: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: s.status })
        const value = R.map(() => ({ status: s.status }), R.pathOr([], ['paymentRefs'], ctx.doc))
        ctx.mergePath({ path: ['paymentRefs'], value })
        ctx.saveDoc()
      },
    },
    noclone: {
      key: true,
      paymentRefs: true,
      payerRef: true,
      phone: true,
      address: true,
      taxId: true,
      note: true,
      sumOfPayments: true,
      allowance: true,
      amount: true,
      status: true,
      date: true,
    },
    computer: {
      '': [
        (value) => {
          const { sumOfPayments, allowance } = value
          const amount = sumOfPayments - allowance

          return {
            amount,
          }
        },
      ],
    },
  },
  data: {
    bundle: {
      payments: { onRemove: 'setNull' },
    },
  },
}

module.exports = {
  ...model,
  views: {
    main: view,
  },
}
