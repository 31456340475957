const model = {
  title: '銷退資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000674,
        label: '銷退編號',
        type: 'String',
        unique: true,
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      dailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報編號', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      time: {
        ragicField: 1000676,
        label: '銷退時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      amount: {
        ragicField: 1000682,
        label: '銷退金額',
        type: 'Number',
      },
      cash: {
        ragicField: 1000675,
        label: '退回現金\\刷卡',
        type: 'Number',
      },
      income: {
        ragicField: 1000677,
        label: '退回收入',
        type: 'String',
      },
      tax: {
        ragicField: 1000678,
        label: '退回稅額',
        type: 'Number',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '銷退門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '銷退門市', ragicField: 1000001 },
        },
      },
      note: {
        ragicField: 1000681,
        label: '銷退事由',
        type: 'String',
      },
      type: {
        ragicField: 1000751,
        label: '退款方式',
        type: 'String',
        input: 'select',
        options: ['現金', '刷卡', '台灣Pay'],
      },
      refundURL: {
        ragicField: 1000973,
        label: '銷退網址',
        type: 'String',
      },
      refundContent: {
        ragicField: 1000971,
        label: '銷退內容',
        type: 'String',
      },
      discountRefund: {
        ragicField: 1001030,
        label: '退回折讓',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001043,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      // key: {
      //   ragicField: 1000508,
      //   label: '客戶編號',
      //   type: 'String',
      //   unique: true,
      // },
    },
  },
  index: {
    retailOrderId: {
      key: {
        'retailOrder.id': 1,
      },
    },
  },
}

const main = {
  title: '銷退資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'retailOrder.key',
        'dailyReport.key',
        'time',
        'amount',
        'cash',
        'income',
        'tax',
        'store.name',
        'note',
        'type',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['retailOrder.id'],
          ['dailyReport.id'],
          ['time'],
          ['amount'],
          ['cash'],
          ['income'],
          ['tax'],
          ['store.id'],
          ['note'],
          ['type'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
