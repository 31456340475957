module.exports = {
  title: '片種代碼',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        label: '片種代碼',
      },
      brandRef: {
        type: 'ObjectId',
        reference: 'brands',
        refKeys: ['key'],
        input: 'select',
        label: '品牌代碼',
      },
      supplierRef: {
        type: 'ObjectId',
        reference: 'suppliers',
        refKeys: ['name'],
        input: 'select',
        label: '供應商名稱',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'brandRef',
        'supplierRef',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['brandRef'],
          ['supplierRef'],
        ],
      },
    ],
  },
}
