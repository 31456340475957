"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_hooks_1 = require("@apollo/react-hooks");
const apollo_boost_1 = require("apollo-boost");
const GET_STOCK_INFOS = apollo_boost_1.gql `
  query ($filter: String!, $warehouseId: String, $offset: Int!, $limit: Int!, $year: Int, $month: Int) {
    getStockInfos(filter: $filter, warehouseId: $warehouseId, offset: $offset, limit: $limit, year: $year, month: $month) {
      _id
      stockInfoId
      stock {
        product {
          key
          name
          centerCost
          storeCost
          type
          brand {
            name
          }
          supplier {
            name
          }
        }
        warehouse
      }
      qty
      averageCost
      totalCost
    }

  }`;
const GET_STOCK_INFOS_WITH_NEGATIVE_QTY = apollo_boost_1.gql `
  query ($warehouseId: String, $offset: Int!, $limit: Int!, $year: Int, $month: Int) {
    getStockInfosWithNegativeQty(warehouseId: $warehouseId, offset: $offset, limit: $limit, year: $year, month: $month) {
      _id
      stockInfoId
      stock {
        product {
          key
          name
          centerCost
          storeCost
          type
          brand {
            name
          }
          supplier {
            name
          }
        }
        warehouse
      }
      qty
      averageCost
      totalCost
    }
  }`;
function ParseData(data) {
    return data.map((e) => {
        if (e.stock.product === null) {
            e.stock.product = {};
        }
        if (e.stock.product.brand === null) {
            e.stock.product.brand = {
                name: '(N/A)',
            };
        }
        if (e.stock.product.supplier === null) {
            e.stock.product.supplier = {
                name: '(N/A)',
            };
        }
        return e;
    });
}
class StockInfosQuery {
    constructor(args) {
        this.args = args;
    }
    get hasCost() {
        return this.args.hasCost;
    }
    get warehouseId() {
        return this.args.warehouseId;
    }
    get year() {
        return this.args.year;
    }
    get month() {
        return this.args.month;
    }
    query(pageNum, pageLimit) {
        return react_hooks_1.useQuery(GET_STOCK_INFOS, {
            variables: {
                offset: (pageNum - 1) * pageLimit,
                limit: pageLimit,
                year: this.args.year,
                month: this.args.month,
                filter: this.args.filter,
                warehouseId: this.args.warehouseId,
            },
        });
    }
    // eslint-disable-next-line class-methods-use-this
    parse(data) {
        return ParseData(data.getStockInfos);
    }
}
class NegativeStockInfosQuery {
    constructor(args) {
        this.args = args;
    }
    get hasCost() {
        return this.args.hasCost;
    }
    get warehouseId() {
        return this.args.warehouseId;
    }
    get year() {
        return this.args.year;
    }
    get month() {
        return this.args.month;
    }
    query(pageNum, pageLimit) {
        return react_hooks_1.useQuery(GET_STOCK_INFOS_WITH_NEGATIVE_QTY, {
            variables: {
                offset: (pageNum - 1) * pageLimit,
                limit: pageLimit,
                year: this.args.year,
                month: this.args.month,
                filter: this.args.filter,
                warehouseId: this.args.warehouseId,
            },
        });
    }
    // eslint-disable-next-line class-methods-use-this
    parse(data) {
        return ParseData(data.getStockInfosWithNegativeQty);
    }
}
function CreateStockInfosQuery(onlyNegative, args) {
    return (onlyNegative
        ? new NegativeStockInfosQuery(args)
        : new StockInfosQuery(args));
}
exports.default = CreateStockInfosQuery;
