const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    processGuardRecords: {
      title: '執行紀錄',
      schema: require('./dataAccess/schema'),
      list: {
        ui: {
          fields: [
            'start',
            'end',
            'shouldClose',
            'closeManually',
            'stdout',
            'stderr',
          ],
        },
      },
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
