import coact from 'coact'

import { config } from './App'

const R = require('coact/ramda')
const syncConfig = require('./syncConfig')

const installRagic = require('./ragic/installClient')

const fullConfig = R.pipe(
  installRagic(syncConfig),
)(config)

coact(fullConfig)
