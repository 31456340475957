const termDefine = {
  sale: {
    tableType: '銷貨',
    accountAction: '收',
    priceName: '售價',
    recipientAction: '送',
    recipientTitle: '客戶名稱',
    recipientPhoneTitle: '客戶電話',
    referTitle: '廠商/維修單號',
  },
  purchase: {
    tableType: '進貨',
    accountAction: '付',
    priceName: '進價',
    recipientAction: '發',
    recipientTitle: '出貨單位',
    recipientPhoneTitle: '電話',
    referTitle: '廠商銷貨單號',
  },
  saleReturn: {
    tableType: '銷貨退回',
    accountAction: '退',
    priceName: '回收價',
    recipientAction: '發',
    recipientTitle: '退貨單位',
    recipientPhoneTitle: '電話',
    referTitle: '廠商/維修單號',
  },
  purchaseReturn: {
    tableType: '進貨退回',
    accountAction: '退',
    priceName: '退回價',
    recipientAction: '發',
    recipientTitle: '供貨單位',
    recipientPhoneTitle: '電話',
    referTitle: '廠商銷貨退回單號',
  },
}

export default termDefine
