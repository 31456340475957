const mergeConfig = require('coact/config/mergeConfig')

const clientModels = {
  storeStatements: {
    views: {
      main: require('../storeStatements/views/main/client'),
    },
  },
}


module.exports = mergeConfig(require('./'), clientModels)
