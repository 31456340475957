const R = require('ramda')
const mergeDeep = require('coact/utils/mergeDeep')

const mergeClientRequiredChanges = require('../../../utils/schema/mergeClientRequiredChanges')

const schema = require('./schema')

const actionsConfig = require('./actions')


const config = {
  title: '門市應付對帳單',
  schema: mergeClientRequiredChanges(schema),
  edit: {
    creator: {
      date: '$$NOW',
      status: '新增',
    },
    // autofill,
    // computer,
    ui: {
      main: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'status'],
          ['buyerRef', 'confirmorRef'],
          ['sellerRef', 'seller.phone', 'seller.taxId', { span: 2, path: 'seller.address' }],
          ['start', { c: 2, path: 'sumOfTradings' }],
          ['end', { c: 2, path: 'allowance' }, 'amount'],
          [{ span: 2, path: 'note', multiline: true }],
        ],
      },
      tradings: {
        title: '交易資料',
        name: 'tableEditor',
        path: 'tradings',
        deletable: false,
        copyable: false,
        appendEmpty: false,
        fields: [
          { path: ['src'], formatter: R.path(['$id', 'text']), view: 'main' },
          'sellerSalesKey',
          'date',
          'payDate',
          'content',
          'status',
          'amount',
        ],
      },
    },
    disabled: {
      key: true,
      date: true,
      status: true,
      amount: true,
      buyerRef: true,
      sellerRef: true,
      start: true,
      end: true,
      sumOfTradings: true,
    },
    // Components: {
    //   PageForm: SalesOrderEditor,
    // },
  },
  list: {
    ui: {
      fields: [
        'key', 'date', 'status', 'buyerRef', 'sellerRef', 'start', 'end', 'amount', 'note',
      ],
    },
  },
}

module.exports = R.reduce(
  mergeDeep,
  config,
  [actionsConfig],
)
