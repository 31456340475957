const model = {
  title: '門市日報資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000589,
        label: '門市日報編號',
        type: 'String',
        unique: true,
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '門市', ragicField: 1000001 },
        },
      },
      date: {
        ragicField: 1000590,
        label: '日期',
        type: 'Date',
        format: 'YYYY-MM-DD',
      },
      start: {
        ragicField: 1000591,
        label: '發票號碼(起)',
        type: 'String',
      },
      end: {
        ragicField: 1000592,
        label: '發票號碼(迄)',
        type: 'String',
      },
      income: {
        ragicField: 1000593,
        label: '營業收入',
        type: 'Number',
      },
      tax: {
        ragicField: 1000594,
        label: '稅額',
        type: 'Number',
      },
      amount: {
        ragicField: 1000595,
        label: '發票金額',
        type: 'Number',
      },
      invaildInvoice: {
        ragicField: 1000596,
        label: '作廢發票號碼',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001041,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}

const main = {
  title: '門市日報資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'store.name',
        'date',
        'start',
        'end',
        'income',
        'tax',
        'amount',
        'invaildInvoice',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['store.id'],
          ['date'],
          ['start'],
          ['end'],
          ['income'],
          ['tax'],
          ['amount'],
          ['invaildInvoice'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
