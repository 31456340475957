const R = require('ramda')

const buildQueryString = require('coact/utils/api/buildQueryString')

const printExcel = (spec) => {
  const { doc, filename, template } = spec
  const queryStirng = buildQueryString({ id: doc._id, fullname: filename })
  const url = `/api/print/${template}/xlsx?${queryStirng}`
  window.open(url)
}

module.exports = {
  edit: {
    actions: {
      storeChecking: {
        text: '門市對帳中',
        effect: { name: 'changeStatusTo', spec: { status: '門市對帳中' } },
        props: { variant: 'contained', color: 'primary' },
      },
      supplierChecking: {
        text: '供應商回覆中',
        effect: { name: 'changeStatusTo', spec: { status: '供應商回覆中' } },
        props: { variant: 'contained', color: 'primary' },
      },
      confirm: {
        text: '對帳完成',
        effect: { name: 'changeStatusTo', spec: { status: '對帳完成' } },
        props: { variant: 'contained', color: 'primary' },
      },
      saveAndCreate: {
        authChecker: () => false,
      },
      saveAndCopy: {
        authChecker: () => false,
      },

      downloadExcel: {
        text: '列印',
        effect: { name: 'downloadExcel' },
        props: { variant: 'outlined', color: 'primary' },
        hotkey: 'ctrl+p',
      },
    },
    effect: {
      changeStatusTo: (effect, ctx) => {
        const { status } = effect.spec
        ctx.editPath({ path: ['status'], value: status })
        ctx.saveDoc()
      },
      downloadExcel: (s, ctx) => {
        const { doc } = ctx

        const key = R.path(['key'], doc)
        const seller = R.path(['sellerRef', 'name'], doc)
        const buyer = R.path(['buyerRef', 'name'], doc)

        const filename = `${key}_${seller}_${buyer}.xlsx`

        printExcel({ doc, filename, template: 'storeStatement' })
      },
    },
  },
}
