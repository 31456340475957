const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    cooper202101Records: {
      title: '酷柏 2021 第一季活動',
      schema: require('./cooper202101Records/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
