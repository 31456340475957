"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const params_1 = require("use-query-params/lib/params");
const use_query_params_1 = require("use-query-params");
exports.default = {
    pageNum: params_1.NumberParam,
    pageLimit: params_1.NumberParam,
    selectedWarehouses: params_1.ArrayParam,
    selectedProduct: params_1.ArrayParam,
    year: params_1.NumberParam,
    month: params_1.NumberParam,
    warehouseId: params_1.StringParam,
    filter: params_1.StringParam,
    onlyNegative: use_query_params_1.BooleanParam,
};
