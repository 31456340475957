const model = {
  title: '發票資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000573,
        label: '發票號碼',
        type: 'String',
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      checker: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '結帳人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '結帳人員', ragicField: 1000588 },
        },
      },
      machine: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '發票機台', reference: 'machines', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '發票機台', ragicField: 1000558 },
        },
      },
      state: {
        ragicField: 1000580,
        label: '狀態',
        type: 'String',
        input: 'select',
        options: ['正常', '報廢'],
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '開立門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '開立門市', ragicField: 1000001 },
        },
      },
      invalidateTime: {
        ragicField: 1000581,
        label: '報廢時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      time: {
        ragicField: 1000577,
        label: '結帳時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      cid: {
        ragicField: 1000578,
        label: '統一編號',
        type: 'String',
      },
      note: {
        ragicField: 1000579,
        label: '備註',
        type: 'String',
      },
      amount: {
        ragicField: 1000582,
        label: '發票金額',
        type: 'Number',
      },
      pack: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '發票卷編號', reference: 'packs', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '發票卷編號', ragicField: 1000566 },
        },
      },
      income: {
        ragicField: 1000584,
        label: '營業收入',
        type: 'Number',
      },
      invoiceDailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '報表編號', reference: 'invoiceDailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '報表編號', ragicField: 1000589 },
        },
      },
      dailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報編號', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      tax: {
        ragicField: 1000586,
        label: '稅額',
        type: 'Number',
      },
      content: {
        ragicField: 1000587,
        label: '發票內容',
        type: 'String',
      },
      payType: {
        ragicField: 1000741,
        label: '付款方式',
        type: 'String',
        input: 'select',
        options: ['現金', '刷卡', '台灣Pay'],
      },
      refund: {
        ragicField: 1000989,
        label: '已退金額',
        type: 'Number',
      },
      updatedAt: {
        ragicField: 1001037,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
}

const main = {
  title: '發票資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'retailOrder.key',
        'checker.key',
        'machine.key',
        'state',
        'store.name',
        'invalidateTime',
        'time',
        'cid',
        'note',
        'amount',
        'pack.key',
        'income',
        'invoiceDailyReport.key',
        'dailyReport.key',
        'tax',
        'content',
        'payType',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['retailOrder.id'],
          ['checker.id'],
          ['machine.id'],
          ['state'],
          ['store.id'],
          ['invalidateTime'],
          ['time'],
          ['cid'],
          ['note'],
          ['amount'],
          ['pack.id'],
          ['income'],
          ['invoiceDailyReport.id'],
          ['dailyReport.id'],
          ['tax'],
          ['content'],
          ['payType'],
          ['refund'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
