const model = {
  title: '台灣Pay資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000992,
        label: '台灣Pay編號',
        type: 'String',
      },
      terminalSerial: {
        ragicField: 1000999,
        label: '端末交易序號',
        type: 'String',
      },
      dailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報_id', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      cardNumber: {
        ragicField: 1001005,
        label: '卡號',
        type: 'String',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '門市_id', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '門市', ragicField: 1000001 },
        },
      },
      type: {
        ragicField: 1001000,
        label: '交易類別',
        type: 'String',
        input: 'select',
        options: ['銷售', '退貨'],
      },
      barcode: {
        ragicField: 1001033,
        label: '條碼',
        type: 'String',
      },
      time: {
        ragicField: 1000995,
        label: '支付時間',
        type: 'Date',
      },
      amount: {
        ragicField: 1000994,
        label: '支付金額',
        type: 'Number',
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      invoiceNumber: {
        ragicField: 1000997,
        label: '發票號碼',
        type: 'String',
      },
      note: {
        ragicField: 1001007,
        label: '註記',
        type: 'String',
      },
      cardType: {
        ragicField: 1001013,
        label: '交易別',
        type: 'String',
        input: 'select',
        options: ['金融卡', '信用卡'],
      },
      srrn: {
        ragicField: 1001034,
        label: '系統追蹤號',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001046,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
}

const main = {
  title: '台灣Pay資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'store.name',
        'amount',
        'time',
        'retailOrder.key',
        'invoiceNumber',
        'dailyReport.key',
        'terminalSerial',
        'type',
        'cardType',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['store.name'],
          ['amount'],
          ['time'],
          ['retailOrder.key'],
          ['invoiceNumber'],
          ['dailyReport.key'],
          ['terminalSerial'],
          ['type'],
          ['cardType'],
          ['srrn'],
          ['note'],
          ['barcode'],
          ['cardNumber'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
