module.exports = {
  title: '品牌資料',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        type: 'Number',
        ragicField: '_ragicId',
        label: 'RagicId',
        disabled: true,
      },
      key: {
        type: 'String',
        label: '品牌編號',
        ragicField: '1000128',
      },
      name: {
        type: 'String',
        ragicField: '1000129',
        label: '品牌名稱',
      },
      brandCode: {
        type: 'String',
        ragicField: '1000130',
        label: '品牌簡碼',
        // ragicParser: 'firstNode',
        // disabled: true,
        // formatter: (staffer) => (!isNil(staffer) ? [staffer.key, staffer.name].join('-') : ''),
      },
      shortName: {
        type: 'String',
        ragicField: '1000131',
        label: '品牌簡稱',
      },
      type: {
        type: 'Array',
        ragicField: '1000103',
        label: '商品類別',
        input: 'select',
        // multi: true,
        contents: [{
          options: ['A', 'B', 'C', 'D', 'E', 'F'],
          type: 'String',
          name: 'options-element',
        }],
      },
      supplierRef: {
        ragicField: '1000001',
        label: '供應商',
        type: 'ObjectId',
        reference: 'suppliers',
        refKeys: ['name'],
        input: 'select',
        ragicParser: 'firstNode',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
        type: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'brandCode',
        'shortName',
        'type',
        'supplierRef',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['name'],
          ['brandCode'],
          ['shortName'],
          ['type'],
          ['supplierRef'],
          ['ragicCenterNodeId'],
          ['ragicStoreNodeId'],
        ],
      },
    ],
  },
}
