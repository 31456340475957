const model = {
  title: '處方資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000532,
        label: '處方編號',
        type: 'String',
        unique: true,
      },
      date: {
        ragicField: 1000549,
        label: '驗光日期',
        type: 'String',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '驗光門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '驗光門市', ragicField: 1000001 },
        },
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      custKeyLink: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '連結客戶編號', reference: 'customers', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '連結客戶編號', ragicField: 1000508 },
        },
      },
      purpose: {
        ragicField: 1000533,
        label: '用途',
        type: 'String',
        input: 'select',
        options: ['二用', '中距', '漸近', '近用', '遠用'],
      },
      type: {
        ragicField: 1000627,
        label: '類型',
        type: 'String',
        input: 'select',
        options: ['隱形', '一般', '其他'],
      },
      source: {
        ragicField: 1000534,
        label: '來源',
        type: 'String',
        input: 'select',
        options: ['新驗', '舊度', '處方', '客指定'],
      },
      rs: {
        ragicField: 1000535,
        label: 'R(S)',
        type: 'String',
      },
      rc: {
        ragicField: 1000536,
        label: 'R(C)',
        type: 'String',
      },
      ra: {
        ragicField: 1000537,
        label: 'R(A)',
        type: 'String',
      },
      radd: {
        ragicField: 1000538,
        label: 'R(ADD)',
        type: 'String',
      },
      ls: {
        ragicField: 1000539,
        label: 'L(S)',
        type: 'String',
      },
      lc: {
        ragicField: 1000540,
        label: 'L(C)',
        type: 'String',
      },
      la: {
        ragicField: 1000541,
        label: 'L(A)',
        type: 'String',
      },
      ladd: {
        ragicField: 1000542,
        label: 'L(ADD)',
        type: 'String',
      },
      pd: {
        ragicField: 1000543,
        label: 'PD',
        type: 'String',
      },
      lpd: {
        ragicField: 1000544,
        label: '單眼PD(L)',
        type: 'String',
      },
      rph: {
        ragicField: 1000545,
        label: '眼位高(R)',
        type: 'String',
      },
      lph: {
        ragicField: 1000546,
        label: '眼位高(L)',
        type: 'String',
      },
      radian: {
        ragicField: 1000606,
        label: '基弧',
        type: 'String',
        input: 'select',
        options: [
          '6.60',
          '6.70',
          '6.80',
          '6.90',
          '7.00',
          '7.10',
          '7.20',
          '7.30',
          '7.40',
          '7.50',
          '7.60',
          '7.70',
          '7.80',
          '7.90',
          '8.00',
          '8.10',
          '8.20',
          '8.30',
          '8.40',
          '8.50',
          '8.60',
          '8.70',
          '8.80',
          '8.90',
          '9.00',
          '38E',
          'S',
          'M',
          'L',
          'LL',
          'SS',
          'XL',
        ],
      },
      prism: {
        ragicField: 1000628,
        label: '稜鏡',
        type: 'String',
      },
      info: {
        ragicField: 1000605,
        label: '配鏡資訊',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001039,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
}

const main = {
  title: '處方資料',
  list: {
    ui: {
      fields: [
        'key',
        'date',
        'store.name',
        'retailOrder.key',
        'purpose',
        'source',
        'type',
        'rs',
        'rc',
        'ra',
        'radd',
        'ls',
        'lc',
        'la',
        'ladd',
        'pd',
        'lpd',
        'rph',
        'lph',
        'radian',
        'prism',
        'info',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['date'],
          ['store.id'],
          ['retailOrder.id'],
          ['custKeyLink.id'],
          ['purpose'],
          ['type'],
          ['source'],
          ['rs'],
          ['rc'],
          ['ra'],
          ['radd'],
          ['ls'],
          ['lc'],
          ['la'],
          ['ladd'],
          ['pd'],
          ['lpd'],
          ['rph'],
          ['lph'],
          ['radian'],
          ['prism'],
          ['info'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
