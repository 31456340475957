const model = {
  title: '員工資料',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        label: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
      code: {
        type: 'String',
        label: '代碼',
        unique: true,
        ragicField: '1000071',
      },
      key: {
        type: 'String',
        label: '員工編號',
        ragicField: '1000018',
        disabled: true,
      },
      storeRef: {
        type: 'ObjectId',
        refKeys: ['name'],
        input: 'select',
        reference: 'stores',
        label: '所屬門市',
        ragicField: '1000001',
      },
      staffName: {
        type: 'String',
        label: '姓名',
        ragicField: '1000020',
      },
      position: {
        label: '職務角色',
        type: 'Array',
        input: 'select',
        // multi: true,
        ragicField: '1000022',
        contents: [{
          options: ['店長', '驗光師', '驗光生', '副店長', '店員', '實習生', '工讀生'],
          type: 'String',
          name: 'options-element',
        }],
      },
      optometristLicenseSpExam: {
        label: '特考驗光師',
        type: 'String',
        options: ['應考未考上', '資格不符', '應考通過'],
        input: 'select',
        ragicField: '1000931',
      },
      optometryLicenseSpExam: {
        label: '特考驗光生',
        type: 'String',
        options: ['應考未考上', '資格不符', '應考通過'],
        input: 'select',
        ragicField: '1000932',
      },
      optometristLicenseHExam: {
        label: '高考驗光師',
        type: 'String',
        options: ['應考未考上', '資格不符', '應考通過'],
        input: 'select',
        ragicField: '1000933',
      },
      optometryLicenseNExam: {
        label: '普考驗光生',
        type: 'String',
        options: ['應考未考上', '資格不符', '應考通過'],
        input: 'select',
        ragicField: '1000934',
      },
      arrivalDate: {
        type: 'Date',
        label: '到職日',
        ragicField: '1000021',
      },
      status: {
        type: 'String',
        label: '工作狀態',
        options: ['在職', '離職'],
        input: 'select',
        default: '在職',
        ragicField: '1000033',
      },
      leaveDate: {
        type: 'Date',
        label: '離職日',
        ragicField: '1000034',
      },
      telephone: {
        type: 'String',
        label: '電話(家)',
        ragicField: '1000024',
      },
      gender: {
        label: '性別',
        type: 'String',
        options: ['男', '女'],
        input: 'select',
        ragicField: '1000035',
      },
      mobile: {
        type: 'String',
        label: '手機',
        ragicField: '1000042',
      },
      birthday: {
        type: 'Date',
        label: '生日',
        ragicField: '1000023',
      },
      email: {
        type: 'String',
        label: 'E-mail',
        ragicField: '1000028',
      },
      liveCity: {
        type: 'String',
        label: '居住縣市',
        ragicField: '1000026',
      },
      liveAddress: {
        type: 'String',
        label: '居住地址',
        ragicField: '1000027',
      },
      contact: {
        type: 'String',
        label: '緊急連絡人',
        ragicField: '1000029',
      },
      contactRelative: {
        type: 'String',
        label: '緊急聯絡人關係',
        ragicField: '1000030',
      },
      contactMobile: {
        type: 'String',
        label: '緊急聯絡人手機',
        ragicField: '1000032',
      },
      contactPhone: {
        type: 'String',
        label: '緊急聯絡人電話',
        ragicField: '1000031',
      },
      maritalStatus: {
        type: 'String',
        label: '婚姻狀態',
        options: ['未婚', '已婚'],
        input: 'select',
        ragicField: '1000039',
      },
      miliraryStatus: {
        type: 'String',
        label: '服役狀態',
        options: ['未役', '役畢'],
        input: 'select',
        ragicField: '1000040',
      },
      note: {
        type: 'String',
        label: '備註',
        ragicField: '1000041',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}


const main = {
  title: '員工資料管理',
  list: {
    ui: {
      fields: [
        'code',
        'key',
        'storeRef',
        'position',
        'status',
        'gender',
        'mobile',
        'note',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['code'],
          ['key'],
          ['staffName'],
          ['storeRef'],
          ['position'],
          ['optometristLicenseSpExam'],
          ['optometryLicenseSpExam'],
          ['optometristLicenseHExam'],
          ['optometryLicenseNExam'],
          ['arrivalDate'],
          ['status'],
          ['leaveDate'],
          ['telephone'],
          ['gender'],
          ['mobile'],
          ['birthday'],
          ['email'],
          ['liveCity'],
          ['liveAddress'],
          ['contact'],
          ['contactRelative'],
          ['contactMobile'],
          ['contactPhone'],
          ['maritalStatus'],
          ['miliraryStatus'],
          ['note'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
