module.exports = {
  title: 'C-系列',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        type: 'Number',
        ragicField: '_ragicId',
        label: 'RagicId',
        disabled: true,
      },
      key: {
        type: 'String',
        label: 'C-系列',
        ragicField: '1000234',
      },
      brandRef: {
        type: 'ObjectId',
        reference: 'brands',
        refKeys: ['key'],
        input: 'select',
        label: '品牌',
        ragicField: '1000128',
      },
      description: {
        type: 'String',
        ragicField: '1000504',
        label: '描述',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'brandRef',
        'description',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['brandRef'],
          ['description'],
        ],
      },
    ],
  },
}
