import * as R from 'ramda'

import models from './models/client'
import menu from './menu'

import DocPrinter from './components/DocPrinter'
import SaleDocPrinter from './components/SaleDocPrinter'
import RecStatementPrinter from './components/RecStatementPrinter'
import PayStatementPrinter from './components/PayStatementPrinter'
import RagicSyncer from './components/RagicSyncer'
import TemplateUploader from './components/TemplateUploader'
import InventoryAdjustmentRoot from './plugins/inventoryAdjustment/InventoryAdjustmentRoot'

import installSaleReport from './saleReport/installClient'
import installCenterStockManagement from './plugins/stockManagement/center/installClient'
import installStoreStockManagement from './plugins/stockManagement/store/installClient'

const baseConfig = {
  title: 'Ctopt App',
  models,
  routes: {
    toRagicSyncer: {
      title: '同步至 Ragic',
      path: '/route/toRagicSyncer',
      component: RagicSyncer,
    },
    uploadTemplate: {
      title: '匯入 XLSX Template',
      path: '/upload/template',
      component: TemplateUploader,
    },
    inventoryAdjustment: {
      title: '庫存調整單',
      path: '/inventoryAdjustment/',
      component: InventoryAdjustmentRoot,
    },

  },
  printers: {
    'sales:main': SaleDocPrinter,
    'purchases:main': DocPrinter,
    'saleReturns:main': DocPrinter,
    'purchaseReturns:main': DocPrinter,
    'storePurchases:main': DocPrinter,
    'storePurchaseReturns:main': DocPrinter,
    'storePurchases:centerStaffers': DocPrinter,
    'storePurchaseReturns:centerStaffers': DocPrinter,
    'recStatements:main': RecStatementPrinter,
    'payStatements:main': PayStatementPrinter,
  },
  menu,
  sagas: {
  },
}

export const config = R.pipe(
  installSaleReport,
  installCenterStockManagement,
  installStoreStockManagement,
)(baseConfig)

// noinspection JSUnusedGlobalSymbols
export default config
