const styles = () => ({
  print: {
    fontFamily: 'PMingLiU',
    fontWeight: 'bold',
    borderColor: 'transparent!important',
    marginTop: 0,
    width: '760px',
    WebkitPrintColorAdjust: 'exact',
  },
  table: {
    display: 'block',
    fontSize: '14px',
    textAlign: 'left',
    width: '760px',
  },
  statement1: {
    display: 'block',
    fontSize: '14px',
    textAlign: 'left',
    width: '760px',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ctext font-size=\'14\' fill=\'%23aaaaaa\' font-family=\'system-ui, sans-serif\' text-anchor=\'middle\' dominant-baseline=\'middle\'%3E%3Ctspan x=\'99%25\' y=\'40%25\'%3E第%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'45%25\'%3E一%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'50%25\'%3E聯%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'65%25\'%3E門%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'70%25\'%3E市%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'75%25\'%3E留%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'80%25\'%3E存%3C/tspan%3E%3C/text%3E%3C/svg%3E")',
  },
  statementPayment1: {
    display: 'block',
    fontSize: '14px',
    textAlign: 'left',
    width: '760px',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ctext font-size=\'14\' fill=\'%23aaaaaa\' font-family=\'system-ui, sans-serif\' text-anchor=\'middle\' dominant-baseline=\'middle\'%3E%3Ctspan x=\'99%25\' y=\'40%25\'%3E第%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'45%25\'%3E一%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'50%25\'%3E聯%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'65%25\'%3E廠%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'70%25\'%3E商%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'75%25\'%3E留%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'80%25\'%3E存%3C/tspan%3E%3C/text%3E%3C/svg%3E")',
  },
  statement2: {
    top: '500px',
    // position: 'absolute',
    display: 'block',
    fontSize: '14px',
    textAlign: 'left',
    width: '760px',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ctext font-size=\'14\' fill=\'%23aaaaaa\' font-family=\'system-ui, sans-serif\' text-anchor=\'middle\' dominant-baseline=\'middle\'%3E%3Ctspan x=\'99%25\' y=\'35%25\'%3E第%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'40%25\'%3E二%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'45%25\'%3E聯%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'60%25\'%3E管%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'65%25\'%3E理%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'70%25\'%3E中%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'75%25\'%3E心%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'80%25\'%3E留%3C/tspan%3E%3Ctspan x=\'99%25\' y=\'85%25\'%3E存%3C/tspan%3E%3C/text%3E%3C/svg%3E")',
  },
  'header-region': {
    position: 'relative',
    left: '0px',
    width: '731px',
    borderBottom: '#000 1px dotted',
  },
  'header-left': {
    position: 'relative',
    left: '0px',
    width: '275px',
  },
  'header-left-print-data': {
    width: '275px',
  },
  seller: {
    height: '32px',
    width: '275px',
    fontFamily: 'PMingLiU',
  },
  'seller-print-data': {
    fontSize: '26px',
  },
  sellerAddress: {
    height: '25px',
  },
  'sellerAddress-print-data': {
    fontSize: '13px',
  },
  sellerPhone: {
    height: '25px',
  },
  'sellerPhone-print-data': {
    fontSize: '13px',
  },
  'header-mid': {
    // display: 'block',
    position: 'relative',
    left: '0px',
    width: '200px',
  },
  'header-mid-print-data': {
    width: '200px',
  },
  'header-mid-form-type': {
    height: '32px',
  },
  'header-mid-form-type-print-data': {
    fontSize: '26px',
  },
  'header-right-top': {
    // display: 'block',
    position: 'relative',
    left: '0px',
    width: '256px',
  },
  'header-right-top-print-data': {
    width: '256px',
  },
  'header-right-top-key': {
    height: '32px',
  },
  'header-right-top-key-print-data': {
    fontSize: '26px',
  },
  'header-right-bottom': {
    position: 'relative',
    top: '-5px',
    left: '0px',
  },
  'header-right-bottom-print-data': {
    width: '456px',
  },
  'header-right-bottom-key': {
    height: '50px',
  },
  'header-right-bottom-key-print-data': {
    fontSize: '50px',
    fontFamily: '"3 of 9 barcode"',
    fontWeight: 'normal',
  },
  'header-right': {
    // display: 'block',
    position: 'relative',
    left: '0px',
    width: '456px',
  },
  /* title sheet */
  'sheet-title-outer-div': {
    lineHeight: '12px',
  },
  'sheet-title': {
    position: 'relative',
    width: '731px',
    borderBottom: '#000 2px solid',
  },
  /* 左 title sheet */
  'title-left': {
    position: 'relative',
    left: '0px',
    width: '450px',
  },
  'title-left-buyerName': {
    fontSize: '20px',
  },
  'title-left-buyerPhone': {
    fontSize: '13px',
  },
  'title-left-buyerStockAddress': {
    fontSize: '13px',
  },
  /* 右 title */
  'title-right': {
    position: 'relative',
    width: '230px',
  },
  'title-right-agentKey': {
    fontSize: '13px',
  },
  'title-right-date': {
    fontSize: '13px',
  },
  'title-right-payDate': {
    fontSize: '13px',
  },
  'title-right-buyerCID': {
    fontSize: '13px',
  },
  /* 頁碼 */
  'page-num': {
    position: 'relative',
    width: '51px',
    fontSize: '13px',
  },
  /* 商品(ItemList) */
  'list-header': {
    position: 'relative',
    borderBottom: '#000 1px dotted',
    left: '0px',
    width: '731px',
    lineHeight: '13px',
  },
  /* 商品 Items */
  'items-outer-div': {
    lineHeight: '12px',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
  },
  items: {
    position: 'relative',
    borderBottom: '#000 0px',
    left: '0px',
    width: '731px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-rank': {
    width: '38px',
  },
  'items-key': {
    width: '151px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-name': {
    width: '276px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-name-short': {
    width: '223px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-qty': {
    width: '34px',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-unit': {
    width: '34px',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-realPrice': {
    width: '53px',
    textAlign: 'right',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  'items-subtotal': {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '53px',
    textAlign: 'right',
  },
  'items-blank': {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10px',
  },
  'items-note': {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '56px',
  },
  'payment-rank': {
    width: '38px',
  },
  'payment-date': {
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    textAlign: 'left',
  },
  'payment-paydate': {
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    textAlign: 'left',
  },
  'payment-content': {
    width: '381px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    textAlign: 'left',
  },
  'payment-amount': {
    width: '56px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    textAlign: 'right',
  },
  /* 頁尾 */
  'page-footer': {
    position: 'relative',
    borderTop: '#000 1px solid',
    left: 0,
    width: '731px',
  },
  'page-footer-col': {
    height: '13px',
    lineHeight: '13px',
    width: '145px',
  },
  /* 頁首 */
  'page-header': {
    position: 'relative',
    left: 0,
    width: '731px',
  },

  'page-header-tr': {
    borderBottom: '#000 1px solid',
  },
  'page-header-key': {
    width: '500px',
  },
  'page-header-page-num': {
    width: '231px',
    textAlign: 'right',
  },
  /* 表尾 */
  'statement-end': {
    position: 'relative',
    borderTop: '#000 1px solid',
    left: 0,
    width: '731px',
    height: '13px',
    lineHeight: '13px',
  },
  'statement-end-tr': {
    height: '13px',
    lineHeight: '13px',
  },
  'statement-end-left': {
    height: '13px',
    lineHeight: '13px',
    width: '400px',
  },
  'statement-end-right-title': {
    height: '13px',
    lineHeight: '13px',
    width: '231px',
  },
  'statement-end-right-value': {
    height: '13px',
    lineHeight: '13px',
    width: '100px',
    textAlign: 'right',
  },
  'statement-value': {
    height: '13px',
    lineHeight: '13px',
    width: '50px',
    textAlign: 'right',
  },

  'page-break': {
    fontSize: '13px',
    lineHeight: '13px',
    pageBreakAfter: 'always',
  },
  'empty-line': {
    width: '731px',
    height: '16px',
    lineHeight: '16px',
  },
})

export default styles
