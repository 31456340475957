import React from 'react'
import Button from '@material-ui/core/Button'

import moment from 'moment'

import * as R from 'ramda'

import { func, object } from 'prop-types'


function formatDate (d) {
  if (R.isNil(d)) return ''
  return moment(d).format('YYYY-MM-DD HH:mm:ss')
}

function renderProgress (progress) {
  if (R.isNil(progress)) return null

  const start = formatDate(R.prop('start', progress))
  const end = formatDate(R.prop('end', progress))
  const error = R.prop('error', progress)
  const logs = R.pipe(
    R.propOr([], 'logs'),
    R.reverse,
    R.map((log) => {
      const { doc, time } = log
      const timeStr = formatDate(time)

      return `${timeStr}: ${doc}`
    }),
  )(progress)
  const stdout = R.propOr('', 'stdout', progress)
  const stderr = R.propOr('', 'stderr', progress)

  return (
    <div>
      {`start: ${start} > end: ${end}`}
      <div>{`stdout: ${stdout}`}</div>
      <div>{`stderr: ${stderr}`}</div>
      {logs.map((row, index) => (
        <div key={index}>{row}</div>
      ))}
      { error
        ? (
          <React.Fragment>
            <div>{error.name}</div>
            <div>{error.message}</div>
            <div>{error.stack}</div>
          </React.Fragment>
        )
        : null
      }
    </div>
  )
}

function RagicSyncerPage ({ launch, watch, close, unlock, progress }) {
  return (
    <div>
      <br />
      <Button
        variant="contained"
        onClick={launch}
      >
        同步資料
      </Button>
      <Button
        variant="contained"
        onClick={watch}
      >
        檢示進度
      </Button>
      <Button
        variant="contained"
        onClick={close}
      >
        強制停止
      </Button>
      <Button
        variant="contained"
        onClick={unlock}
      >
        強制解鎖
      </Button>
      {renderProgress(progress)}
    </div>
  )
}
RagicSyncerPage.propTypes = {
  watch: func,
  launch: func,
  close: func,
  unlock: func,
  progress: object,
}

export default RagicSyncerPage
