const R = require('ramda')

module.exports = {
  title: '貨款資料',
  schema: {
    type: 'Object',
    contents: {
      srcDBRef: {
        type: 'DBRef',
        label: '來源',
        formatter: (doc) => {
          if (!doc) return ''
          const key = R.path(['$id', 'key'], doc)
          return `${key}`
        },
      },
      date: { type: 'Date', label: '日期' },
      payerRef: {
        type: 'ObjectId', label: '付款單位', reference: 'parties', refKeys: ['name'],
      },
      receiverRef: {
        type: 'ObjectId', label: '收款單位', reference: 'parties', refKeys: ['name'],
      },
      status: {
        type: 'String',
        label: '狀態',
        creator: '新增',
        options: ['新增', '確認', '對帳中', '已結'],
        width: 100,
      },
      amount: { type: 'Number', label: '金額', width: 70 },
      content: { type: 'String', label: '內容', width: 300 },
      statementRef: {
        type: 'DBRef',
        label: '對帳單',
        formatter: (doc) => {
          if (!doc) return ''
          const key = R.path(['$id', 'key'], doc)
          return `${key}`
        },
      },
    },
  },
  index: {
    receiverRef: {
      key: { receiverRef: 1, payerRef: 1, status: 1, amount: 1, date: 1 },
    },
  },
  export: {
    fields: [
      'srcDBRef',
      'status',
      { path: 'receiverRef.name', label: '收款單位' },
      { path: 'payerRef.name', label: '付款單位' },
      { path: 'date', label: '結帳日期' },
      { path: 'srcDBRef.$id.date', label: '單據日期', type: 'Date' },
      { path: 'srcDBRef.$id.sellerRef.name', label: '單據賣方' },
      { path: 'srcDBRef.$id.buyerRef.name', label: '單據買方' },
      'content',
      'amount',
    ],
    populations: '*',
    populationdepth: 2,
  },
}
