const mergeDeep = require('coact/utils/mergeDeep')


const clientRequiredChanges = {
  contents: {
    '@@createdAt': { noclone: true, required: false },
    '@@updatedAt': { noclone: true, required: false },
    '@@frozenAt': { noclone: true, required: false },
    '@group': { noclone: true },
    '@groups': { noclone: true },
    '@user': { noclone: true },
  },
}


module.exports = function mergeClientRequiredChanges (schema) {
  return mergeDeep(schema, clientRequiredChanges)
}
