module.exports = {
  title: '供應商資料',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        label: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
      key: {
        ragicField: '1000070',
        type: 'String',
        label: '代碼',
        unique: true,
      },
      name: {
        type: 'String',
        label: '供應商編號',
        ragicField: '1000001',
      },
      alias: {
        type: 'String',
        label: '供應商別名(新舊資料轉換階段)',
      },
      organize: {
        ragicField: '1000476',
        type: 'String',
        label: '組織類別',
        disabled: true,
      },
      supplierName: {
        ragicField: '1000135',
        type: 'String',
        label: '供應商名稱',
      },
      shortName: {
        ragicField: '1000002',
        type: 'String',
        label: '供應商簡稱',
      },
      owner: {
        ragicField: '1000009',
        type: 'String',
        label: '負責人',
      },
      CID: {
        ragicField: '1000005',
        type: 'String',
        label: '統一編號',
      },
      contact: {
        ragicField: '1000048',
        type: 'String',
        label: '聯絡人',
      },
      phone: {
        ragicField: '1000006',
        type: 'String',
        label: '供應商電話',
      },
      phone2: {
        ragicField: '1000134',
        type: 'String',
        label: '備用電話',
      },
      FAX: {
        ragicField: '1000007',
        type: 'String',
        label: '供應商傳真',
      },
      address: {
        ragicField: '1000008',
        type: 'String',
        label: '供應商地址',
      },
      storageMA: {
        ragicField: '1000472',
        type: 'String',
        label: '庫存管理',
        options: ['執行', '不執行'],
        input: 'select',
      },
      productCAT: {
        type: 'Array',
        ragicField: '1000103',
        label: '商品類別',
        input: 'select',
        multi: true,
        contents: [{
          options: ['A', 'B', 'C', 'D', 'E'],
          type: 'String',
          name: 'options-element',
        }],
      },
      status: {
        ragicField: '1000061',
        type: 'String',
        label: '供應商狀態',
        options: ['啟用', '不啟用'],
        input: 'select',
      },
      supplyType: {
        ragicField: '1000102',
        type: 'String',
        label: '供應商品',
      },
      paymentMethod: {
        ragicField: '1000052',
        type: 'String',
        label: '付款方式',
        options: ['月結'],
        input: 'select',
      },
      netDays: {
        ragicField: '1000053',
        type: 'String',
        label: '付款天數',
      },
      prepaidBalance: {
        ragicField: '1000054',
        type: 'String',
        label: '預付款餘額',
      },
      isContractor: {
        ragicField: '1000055',
        type: 'String',
        label: '是否為簽約商',
        options: ['否', '是'],
        input: 'select',
      },
      note: {
        ragicField: '1000017',
        type: 'String',
        label: '備註',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
      userRef: {
        type: 'ObjectId',
        reference: 'users',
        label: '權限使用者',
        refKeys: ['account'],
        input: 'select',
      },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'alias',
        'supplierName',
        'productCAT',
        'status',
        'supplyType',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 250, 1: 250 },
        fields: [
          ['name', 'alias'],
          [{ path: 'supplierName', span: 2 }],
          ['shortName', 'key'],
          ['owner'],
          ['CID'],
          ['contact'],
          ['phone', 'phone2'],
          ['FAX'],
          [{ path: 'address', span: 2 }],
          ['storageMA'],
          ['productCAT', 'status'],
          [{ path: 'supplyType', span: 2 }],
          ['paymentMethod', 'netDays'],
          ['prepaidBalance', 'isContractor'],
          [{ path: 'note', span: 2 }],
          ['userRef'],
        ],
      },
    ],
  },
}
