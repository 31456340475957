const model = {
  title: '貨品條碼',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        label: '貨品條碼',
      },
      productKey: {
        type: 'String',
        label: 'code1',
      },
      serialKey: {
        type: 'String',
        label: 'code2',
      },
      serialNo: {
        type: 'String',
        label: '商品序號',
      },
      // itemStatementKey: {
      //   type: 'String',
      //   label: '貨單品項編號',
      // },
      number: {
        type: 'Number',
        label: 'number',
      },
      // cost: {
      //   type: 'Number',
      //   label: '計價',
      // },
      salePrice: {
        type: 'Number',
        label: 'price',
      },
      importKey: {
        type: 'String',
        label: '匯入批號',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
}

const mainView = {
  title: '貨品條碼',
  list: {
    ui: {
      fields: [
        'productKey',
        'serialKey',
        'number',
        'salePrice',
        'importKey',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['productKey'],
          ['serialKey'],
          ['serialNo'],
          // ['itemStatementKey'],
          // ['cost'],
          ['salePrice'],
          ['importKey'],
        ],
      },
    ],
  },
  export: {
    fields: [
      'productKey',
      'serialKey',
      'number',
      'salePrice',
      'importKey',
    ],
    populations: '*',
    populationdepth: 2,
  },
}

module.exports = {
  ...model,
  views: {
    main: mainView,
  },
}
