/**
  {
    run: ['cache', 'adapt', 'link', 'save'],
    plan: 'updateDirty', // 'cacheAll', 'refreshAll', 'updateDirty'
    path: 'store/7',
    saveTo: 'retailOrders', // save to retailOrders.ragic
    updatedAtEid: '1001042',
    batchSize: 1000,
  }
*/

/*
const defaultTask = {
  plan: 'updateAll',
  adaptPlan: 'newly',
  linkPlan: 'newly',
  savePlan: 'newly',
  run: ['cache', 'adapt', 'link', 'save'],
  batchSize: 1000,
  subtables: false,
}
*/
// TODO merge default tasks when installSyncer

module.exports = {
  syncConfigPath: 'src/syncConfig.js',
  appConfigPath: 'src/serverConfig.js',
  syncTasks: [
    // /*
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'invoice/1',
      saveTo: 'machines',
      updatedAtEid: '1001035',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/7',
      saveTo: 'retailOrders',
      updatedAtEid: '1001042',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/1',
      saveTo: 'customers',
      updatedAtEid: '1001038',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/3',
      saveTo: 'prescripts',
      updatedAtEid: '1001039',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/4',
      saveTo: 'retailSales',
      updatedAtEid: '1001040',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/8',
      saveTo: 'retailSaleReturns',
      updatedAtEid: '1001043',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'invoice/8',
      saveTo: 'packs',
      updatedAtEid: '1001036',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'invoice/6',
      saveTo: 'invoices',
      updatedAtEid: '1001037',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/5',
      saveTo: 'invoiceDailyReports',
      updatedAtEid: '1001041',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/13',
      saveTo: 'creditCards',
      updatedAtEid: '1001044',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/20',
      saveTo: 'taiwanpays',
      updatedAtEid: '1001046',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'storereport/3',
      saveTo: 'dailyReports',
      updatedAtEid: '1001047',
    },
    {
      run: ['cache', 'adapt', 'link', 'save'],
      plan: 'updateDirty',
      path: 'store/15',
      saveTo: 'miscellaneous',
      updatedAtEid: '1001045',
    },
  ],
  models: {
    dailyReports: { schema: require('./models/dailyReports').schema },
    machines: { schema: require('./models/machines').schema },
    customers: { schema: require('./models/customers').schema },
    retailOrders: { schema: require('./models/retailOrders').schema },
    prescripts: { schema: require('./models/prescripts').schema },
    retailSales: { schema: require('./models/retailSales').schema },
    packs: { schema: require('./models/packs').schema },
    invoiceDailyReports: { schema: require('./models/invoiceDailyReports').schema },
    invoices: { schema: require('./models/invoices').schema },
    retailSaleReturns: { schema: require('./models/retailSaleReturns').schema },
    creditCards: { schema: require('./models/creditCards').schema },
    taiwanpays: { schema: require('./models/taiwanpays').schema },
    miscellaneous: { schema: require('./models/miscellaneous').schema },
  },
}
