const model = {
  title: '其他收支資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000883,
        label: '其他收支編號',
        type: 'String',
        unique: true,
      },
      dailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報編號', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      amount: {
        ragicField: 1000885,
        label: '收支金額',
        type: 'Number',
      },
      receiptType: {
        ragicField: 1000913,
        label: '收據類別',
        type: 'String',
        input: 'select',
        options: ['二聯式發票', '三聯式發票', '免開統一發票', '估價單'],
      },
      receiptNo: {
        ragicField: 1000886,
        label: '發票號碼',
        type: 'String',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '門市_id', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '門市', ragicField: 1000001 },
        },
      },
      type: {
        ragicField: 1000919,
        label: '收支類別',
        type: 'String',
        input: 'select',
        options: ['收入', '支出'],
      },
      receiptDate: {
        ragicField: 1000912,
        label: '收據日期',
        type: 'Date',
      },
      reportDate: {
        ragicField: 1000967,
        label: '報表日期',
        type: 'Date',
      },
      haveCID: {
        ragicField: 1000887,
        label: '有無統編',
        type: 'String',
        input: 'select',
        options: ['有', '無'],
      },
      account: {
        ragicField: 1000888,
        label: '科目',
        type: 'String',
      },
      note: {
        ragicField: 1000889,
        label: '內容',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001045,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
}

const main = {
  title: '其他收支資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'amount',
        'receiptType',
        'receiptNo',
        'store.name',
        'type',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['type'],
          ['amount'],
          ['receiptType'],
          ['receiptNo'],
          ['note'],
          ['haveCID'],
          ['account'],
          ['receiptDate'],
          ['store.id'],
          ['reportDate'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
