const mergeConfig = require('coact/config/mergeConfig')

const Page = require('./PageComponent')


const config = {
  routes: {
    ragicSyncer: {
      title: '從 Ragic 同步資料',
      path: '/route/ragicSyncer',
      component: Page,
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
