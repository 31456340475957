const model = {
  title: '發票機台資料',
  schema: {
    type: 'Object',
    contents: {
      _id: { type: 'ObjectId' },
      key: {
        ragicField: 1000558,
        label: '發票機台',
        type: 'String',
      },
      // storeRef: {
      //   ragicField: 1000001,
      //   label: '所屬門市',
      //   type: 'ObjectId',
      //   reference: 'stores',
      //   refKeys: ['name'],
      //   input: 'select',
      // },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '門市_id', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '所屬門市', ragicField: 1000001 },
        },
      },
      mac: {
        ragicField: 1000559,
        label: '網卡編號',
        type: 'String',
      },
      port: {
        ragicField: 1000560,
        label: '連接埠',
        type: 'String',
      },
      usingMonth: {
        ragicField: 1000561,
        label: '使用中發票月份',
        type: 'String',
        input: 'select',
        options: ['0102', '0304', '0506', '0708', '0910', '1112'],
      },
      usingDate: {
        ragicField: 1000601,
        label: '使用中營業日',
        type: 'Date',
        format: 'YYYY-MM-DD',
      },
      usingAlphabet: {
        ragicField: 1000563,
        label: '使用中英文字母',
        type: 'String',
      },
      nextInvoiceKey: {
        ragicField: 1000564,
        label: '目前發票號碼',
        type: 'String',
      },
      usingPackKey: {
        ragicField: 1000603,
        label: '使用中發票卷',
        type: 'String',
      },
      remainInvoicePiece: {
        ragicField: 1000565,
        label: '剩餘發票張數',
        type: 'String',
      },
      taiwanpayVerificationCode: {
        ragicField: 1001032,
        lable: '台灣Pay驗證參數',
        type: 'String',
      },
      creditCardSupplier: {
        ragicField: 1000988,
        lable: '刷卡機銀行',
        type: 'String',
        input: 'select',
        options: ['一銀', '台新'],
      },
      taiwanpayPass: {
        ragicField: 1001008,
        lable: '台灣Pay開通',
        type: 'String',
        input: 'select',
        options: ['是', '否'],
      },
      taiwanpayMerchantId: {
        ragicField: 1001031,
        lable: '台灣Pay特店代碼',
        type: 'String',
      },
      taiwanpayTerminalId: {
        ragicField: 1001014,
        lable: '台灣Pay端末代碼',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001035,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      // usingDailyReportKeyRef: {
      //   ragicField: 1000631,
      //   label: '使用中業績表',
      //   type: 'ObjectId',
      //   reference: 'dailyReports',
      //   refKeys: ['key'],
      //   input: 'select',
      // },
      // usingInvoiceDailyReportKeyRef: {
      //   ragicField: 1000589,
      //   label: '使用中日報表',
      //   type: 'ObjectId',
      //   reference: 'invoiceDailyReports',
      //   refKeys: ['key'],
      //   input: 'select',
      // },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}

const main = {
  title: '發票機台資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'storeRef',
        'mac',
        'port',
        'usingMonth',
        'usingDate',
        'usingAlphabet',
        'nextInvoiceKey',
        'usingPackKey',
        'remainInvoicePiece',
        'usingDailyReportKeyRef',
      ],
    },
  },
  edit: {
    ui: [
      {
        label: 'formEditor',
        fields: [
          ['key'],
          ['storeRef'],
          ['mac'],
          ['port'],
          ['usingMonth'],
          ['usingDate'],
          ['usingAlphabet'],
          ['nextInvoiceKey'],
          ['usingPackKey'],
          ['remainInvoicePiece'],
          ['usingDailyReportKeyRef'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
