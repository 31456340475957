module.exports = {
  title: '收支資料',
  schema: {
    type: 'Object',
    contents: {
      date: {
        type: 'Date',
        format: 'YYYY-MM-DD',
        label: '日期',
      },
      accountRef: {
        type: 'ObjectId',
        reference: 'accounts',
        refKeys: ['accountCode', 'account'],
        label: '科目代碼',
        input: 'select',
      },
      paymentMethodRef: {
        type: 'ObjectId',
        reference: 'paymentMethods',
        label: '收付方式',
        refKeys: ['paymentMethod'],
        input: 'select',
      },
      storeRef: {
        type: 'ObjectId',
        reference: 'stores',
        label: '店別',
        refKeys: ['name'],
        input: 'select',
      },
      amount: {
        type: 'Number',
        label: '金額',
      },
      cashflowDirection: {
        type: 'String',
        label: '收支類別',
        input: 'select',
        options: ['收入', '支出'],
      },
      detail: {
        type: 'String',
        label: '內容',
      },
      notice: {
        type: 'String',
        label: '備註',
      },
      supplierRef: {
        type: 'ObjectId',
        reference: 'suppliers',
        label: '供應商',
        refKeys: ['name'],
        input: 'select',
      },
      stafferRef: {
        type: 'ObjectId',
        reference: 'staffers',
        refKeys: ['key'],
        label: '人員',
        input: 'select',
      },
      miscellaneousNum: {
        type: 'String',
        label: '其他收支編號',
      },
    },
  },
  list: {
    ui: {
      fields: [
        'date',
        'accountRef',
        'paymentMethodRef',
        'storeRef',
        'amount',
        'cashflowDirection',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          [{ path: 'name', span: 2 }],
          ['date'],
          ['storeRef', 'accountRef', 'paymentMethodRef'],
          ['cashflowDirection', 'amount'],
          ['detail'],
          ['notice'],
          ['supplierRef'],
          ['stafferRef'],
          ['miscellaneousNum'],
        ],
      },
    ],
  },
}
