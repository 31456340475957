const moment = require('moment')

const mergeSystemFields = require('../../utils/schema/mergeSystemFields')

const prefix = 'SPS'
const format = 'YYYYMM'
const digits = 3

const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '單號',
      counter: {
        key: () => `${prefix}${moment().format(format)}`,
        value: (key, value) => `${key}-${value.toString().padStart(digits, 0)}`,
      },
    },
    date: { type: 'Date', label: '開單日期' },
    status: {
      type: 'String',
      label: '狀態',
      options: ['新增', '門市對帳中', '供應商回覆中', '對帳完成', '已結'],
    },

    buyerRef: { type: 'ObjectId', label: '應付單位', reference: 'parties', refKeys: ['name'] },
    sellerRef: { type: 'ObjectId', label: '供應商', reference: 'parties', refKeys: ['name'] },
    seller: {
      type: 'Object',
      contents: {
        name: { name: '名稱', type: 'String' },
        phone: { name: '電話', type: 'String' },
        address: { name: '地址', type: 'String' },
        taxId: { name: '統編', type: 'String' },
      },
    },

    confirmorRef: { type: 'ObjectId', label: '對帳人員', reference: 'staffers', refKeys: ['key'] },

    start: { type: 'Date', label: '開始日期' },
    end: { type: 'Date', label: '結束日期' },

    sumOfTradings: { type: 'Number', label: '應付金額' },
    allowance: { type: 'Number', label: '折讓' },
    amount: { type: 'Number', label: '總計' },
    note: { type: 'String', label: '對帳意見' },
    // orderDBRefs: {
    //   type: 'Array',
    //   contents: [{ type: 'DBRef' }],
    // },
  },
})

module.exports = {
  title: '門市應付對帳單',
  schema,
}
