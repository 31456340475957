module.exports = {
  title: 'A類顏色',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        type: 'Number',
        ragicField: '_ragicId',
        label: 'RagicId',
        disable: true,
      },
      key: {
        type: 'String',
        label: '顏色',
        unique: true,
        ragicField: '1000118',
      },
      description: {
        type: 'String',
        label: '描述',
        ragicField: '1000488',
      },
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'description',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          ['key'],
          ['description'],
        ],
      },
    ],
  },
}
