module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    start: { type: 'Date', label: '開始時間', format: 'YYYY-MM-DDTHH:mm:ss' },
    end: { type: 'Date', label: '結束時間', format: 'YYYY-MM-DDTHH:mm:ss' },
    shouldClose: { type: 'Boolean', label: '應結束' },
    closeManually: { type: 'Boolean', label: '手動關閉' },
    stdout: { type: 'string', label: 'stdout', width: 200 },
    stderr: { type: 'string', label: 'stderr', width: 200 },
    logs: {
      type: 'Array',
      contents: [{
        type: 'Object',
        contents: {
          time: { type: 'Date' },
          doc: { type: 'String' },
        },
      }],
    },
  },
}
