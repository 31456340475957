const { map, path } = require('coact/ramda')

const model = {
  title: '商品資料',
  schema: {
    type: 'Object',
    contents: {
      ragicField: {
        label: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
      key: {
        type: 'String',
        label: '商品條碼',
        ragicField: '1000064',
      },
      name: {
        type: 'String',
        label: '商品名稱',
        ragicField: '1000065',
      },
      type: {
        type: 'String',
        label: '商品類別',
        input: 'select',
        options: ['A', 'B', 'C', 'D', 'E', 'F'],
        ragicField: '1000103',
      },
      brandRef: {
        type: 'ObjectId',
        reference: 'brands',
        refKeys: ['key'],
        input: 'select',
        label: '品牌代碼',
        ragicField: '1000128',
      },
      brandCode: {
        type: 'String',
        label: '品牌簡碼',
        ragicField: '1000106',
      },
      supplierRef: {
        type: 'ObjectId',
        reference: 'suppliers',
        label: '供應商名稱',
        refKeys: ['name'],
        input: 'select',
        ragicField: '1000001',
      },
      orderCode: {
        type: 'String',
        label: '訂貨代碼',
        ragicField: '1000186',
      },
      // fields never use
      isContract: {
        type: 'String',
        input: 'select',
        label: '是否為簽約商品',
        options: ['是', '否'],
        ragicField: '1000119',
      },
      orderFrom: {
        type: 'String',
        label: '訂貨單位',
        ragicField: '1000120',
      },
      payType: {
        type: 'String',
        input: 'select',
        label: '付款方式',
        options: ['現金', '匯款', '支票'],
        ragicField: '1000121',
      },
      spec: {
        type: 'String',
        label: '規格',
        ragicField: '1000177',
      },
      taxType: {
        type: 'String',
        label: '稅別',
        input: 'select',
        options: ['內含', '外加'],
        ragicField: '1000440',
      },
      basicAmount: {
        type: 'String',
        label: '基數',
        ragicField: '1000182',
      },
      // 商品圖片?
      // common spec.
      origin: {
        type: 'String',
        label: '產地',
        ragicField: '1000108',
        input: 'select',
        options: [
          '韓國',
          '香港',
          '台灣',
          '日本',
          '中國',
          '義大利',
          '法國',
          '愛爾蘭/美國',
          '英國/美國',
          '大陸',
          '東南亞',
          '菲律賓',
          '英商MONDOTTICA監製 (中國)',
          '德商STEPPER監製 (中國)',
          '中國/德商STEPPER監製',
          '日商HUG OZAWA監製(中國)',
        ],
      },
      unit: {
        type: 'String',
        label: '單位',
        ragicField: '1000268',
        input: 'select',
        options: ['支', '盒', '個', '台', '組', '本', '打', '條', '箱', '包', '片', '卷', '副'],
      },
      note: {
        type: 'String',
        label: '備註',
        ragicField: '1000122',
      },
      // prices
      centerCost: {
        type: 'Number',
        label: '管理中心成本',
        ragicField: '1000132',
      },
      storeCost: {
        type: 'Number',
        label: '門市成本',
        ragicField: '1000109',
      },
      price: {
        type: 'Number',
        label: '定價',
        ragicField: '1000110',
      },
      staffPrice: {
        type: 'Number',
        label: '員配價',
        ragicField: '1000416',
      },
      salePrice: {
        type: 'Number',
        label: '售價',
        ragicField: '1000111',
      },
      psPrice: {
        type: 'Number',
        label: '磐軒價',
        ragicField: '1001076',
      },
      clPrice: {
        type: 'Number',
        label: '全聯價',
        ragicField: '1001075',
      },
      // A rim
      model: {
        type: 'String',
        label: '型號',
        ragicField: '1000112',
      },
      color: {
        type: 'String',
        label: '顏色',
        ragicField: '1000118',
      },
      colorCode: {
        type: 'String',
        label: '色號',
        ragicField: '1000116',
      },
      size: {
        type: 'Number',
        label: '尺寸',
        ragicField: '1000114',
      },
      material: {
        type: 'String',
        label: '材質',
        ragicField: '1000113',
        input: 'select',
        options: [
          'F-塑膠',
          'B-混合',
          'C-板料',
          'A-合金',
          'D-純鈦',
          'E-TR90',
          'G-β鈦',
          'I-板鈦',
          'H-記憶鈦',
          'J-Ultem',
          'K-INFINO',
          'L-Grilamid',
          'M-TR1000',
          'N-板料/TR',
          'O-Z鈦',
          'P-塑鋼',
          'Q-不鏽鋼',
          'R-半鈦',
          'S-鋁鎂',
          'Metal',
          'Titanium',
          'Steel',
          'Acetate',
          'Injected',
          'Propionate',
          'Nylon',
          'Peek',
          'Carbon fiber',
        ],
      },
      contour: {
        type: 'String',
        label: '外型',
        ragicField: '1000115',
        input: 'select',
        options: [
          'L-一般型',
          'M-方圓型',
          'E-加掛',
          'I-長方型',
          'H-長扁型',
          'R-大框一般型',
          'O-大框長方型',
          'T-小框長扁型',
          'X-小框一般型',
          'U-小框長方型',
          'D-兒童',
          'V-小框圓型',
          'J-圓型',
          'K-橢圓型',
          'C-運動',
          'F-太陽架',
          'Y-小框方圓型',
          'Z-特殊型',
          'W-小框橢圓型',
          'S-大框方圓型',
          'N-大框長扁型',
          'G-太陽架(兒童)',
          'A-傳統男框',
          'Q-大框橢圓型',
          'P-大框圓型',
          'R-太陽眼鏡(徧光)',
          'F3-運動太陽眼鏡(水銀偏光防霧)',
          'F4-運動太陽眼鏡(偏光防霧)',
          'Z1-多邊形',
          'Round',
          'Rectangle',
          'Pillow',
          'Oval',
          'Phantos',
          'Pilot',
          'Square',
          'Irregular',
          'Butterfly',
          'Cat Eye',
          'Oval ',
          'Square ',
          'Pilot ',
          '雙槓雷朋',
          '雙槓造型',
        ],
      },
      rimType: {
        type: 'String',
        label: '類型',
        ragicField: '1000117',
        input: 'select',
        options: ['A-全框', 'B-半框', 'C-無邊(一般)', 'D-無邊(思樂)', 'E-摺疊', 'F-掛片', 'I-長方型', 'OPTICAL', 'SUN', 'CLIP-ON'],

      },
      templeLength: {
        type: 'Number',
        label: '腳長',
        ragicField: '1000185',
      },
      // B glasses
      lensTypeRef: {
        type: 'ObjectId',
        reference: 'lensTypes',
        refKeys: ['key'],
        input: 'select',
        label: '片種代碼',
      },
      sphereMax: {
        type: 'Number',
        label: 'S',
        ragicField: '1000156',
      },
      sphereMin: {
        type: 'Number',
        label: '~S',
        ragicField: '1000157',
      },
      cylinderMax: {
        type: 'Number',
        label: 'C',
        ragicField: '1000158',
      },
      cylinderMin: {
        type: 'Number',
        label: '~C',
        ragicField: '1000159',
      },
      axisMax: {
        type: 'Number',
        label: 'A',
        ragicField: '1000192',
      },
      axisMin: {
        type: 'Number',
        label: '~A',
        ragicField: '1000193',
      },
      addMax: {
        type: 'Number',
        label: 'ADD',
        ragicField: '1000160',
      },
      addMin: {
        type: 'Number',
        label: '~ADD',
        ragicField: '1000161',
      },
      SCMax: {
        type: 'Number',
        label: 'S+C',
        ragicField: '1000162',
      },
      SCMin: {
        type: 'Number',
        label: '~S+C',
        ragicField: '1000506',
      },
      progressive: {
        type: 'Number',
        label: '累進帶',
        ragicField: '1000232',
      },
      diameter: {
        type: 'Number',
        label: '直徑',
        ragicField: '1000187',
      },
      coating: {
        type: 'String',
        label: '多層膜',
        ragicField: '1000165',
      },
      ultraviolet: {
        type: 'String',
        label: 'UV',
        ragicField: '1000188',
      },
      refractiveIndex: {
        type: 'String',
        label: '折射率',
        ragicField: '1000163',
      },
      blank: {
        type: 'String',
        label: '毛料',
        ragicField: '1000164',
      },
      blankShort: {
        type: 'String',
        label: '毛料簡稱',
      },
      storageType: {
        type: 'String',
        label: '庫存/訂製',
        ragicField: '1000231',
      },
      deliveryTime: {
        type: 'String',
        label: '交期',
        ragicField: '1000233',
      },
      design: {
        type: 'String',
        label: '設計',
        ragicField: '1000166',
      },
      // C contact
      seriesRef: {
        type: 'ObjectId',
        reference: 'serieses',
        refKeys: ['key'],
        input: 'select',
        label: '商品系列名稱',
        ragicField: '1000234',
      },
      radius: {
        type: 'Number',
        label: '基弧',
        ragicField: '1000168',
      },
      replacementFrequency: {
        type: 'Number',
        label: '替換週期',
        ragicField: '1000170',
      },
      lensColor: {
        type: 'String',
        label: '隱形眼鏡顏色',
        ragicField: '1000172',
      },
      Ctype: {
        type: 'String',
        label: '類別',
        ragicField: '1000174',
      },
      waterContent: {
        type: 'Number',
        label: '含水量',
        ragicField: '1000175',
      },
      packingQuantity: {
        type: 'Number',
        label: '片數',
        ragicField: '1000194',
      },
      // D
      Dseries: {
        type: 'String',
        label: '系列(D)',
        ragicField: '1000176',
      },
      Dunit: {
        type: 'String',
        label: '單位(D)',
        ragicField: '1000178',
      },
      Dpackage: {
        type: 'String',
        label: '包裝(D)',
        ragicField: '1000179',
      },
      // E
      Etype: {
        type: 'String',
        label: '分類',
        input: 'select',
        options: [
          'B-矽膠鼻墊',
          'A-UV機',
          'A-無邊溝槽機',
          'D-眼鏡盒',
          'E-掃描槍',
          'A-詩樂工具組',
          'C-幻燈機燈管',
          'D-工具盒螺絲起子',
          'C-3M貼紙',
          'D-工具盒',
          'B-RENOMA運動鏡腳',
          'B-詩樂無邊套管',
          'B-磨片機吸盤',
          'A-烘烤機',
          'A-無邊鑽孔機',
          'A-超音波清洗機',
          'C-下巴紙',
          'C-奈米筆',
          'D-眼鏡繩',
          'D-珠鍊',
          'C-電腦驗光感熱紙',
          'D-中天紙袋',
          'D-中天塑膠袋',
          'B-T型墊片(中)',
          'B-T型墊片(小)',
          'B-氣壓式鼻墊',
          'B-全塑鼻墊',
          'B-六角無邊長螺絲',
          'B-金屬螺帽',
          'B-合口螺絲',
          'B-鼻墊螺絲',
          'B-圓腳套',
          'B-扁腳套',
          'B-矽膠鼻墊-零訂',
          'B-塑膠ㄇ型墊片(詩樂無邊)',
          'B-塑膠T型墊片',
          'B-透明塑膠墊片',
          'B-金屬無邊墊片',
          'B-螺絲帽',
          'B-螺絲母',
          'B-無邊框螺絲',
          'B-膠框螺絲',
          'B-鏡腳螺絲',
          'B-鏡片/鏡腳螺絲',
        ],
        ragicField: '1000180',
      },
      Espec: {
        type: 'String',
        label: '規格(E)',
        ragicField: '1000181',
      },
      Ecode: {
        type: 'String',
        label: 'E類商品代碼',
        ragicField: '1000439',
      },
      lockPrice: {
        type: 'String',
        input: 'select',
        label: '價格鎖定',
        options: ['非鎖定', '鎖定'],
        ragicField: '1000968',
        creator: '非鎖定',
      },
      bonusFocalLength: {
        type: 'String',
        input: 'select',
        name: '獎金分類-焦距',
        options: ['多焦', '單焦', '加工', '舒壓', '近近', '中近', '雙光', '學生', '子母片', '兒控', '無獎金'],
        ragicField: '1000986',
      },
      bonusColoredLense: {
        type: 'String',
        input: 'select',
        name: '獎金分類-變色',
        options: ['偏光', '加工', '透明', '全視線', '變色龍', 'ALLPRO', '東海變色', '永信全能變色', 'ITO變色', '420', '高對比', '無獎金'],
        ragicField: '1000987',
      },
      // sync
      ragicStoreNodeId: {
        type: 'Number',
        label: 'Ragic門市資料Id',
        noclone: true,
      },
      ragicCenterNodeId: {
        type: 'Number',
        label: 'Ragic管理中心資料Id',
        noclone: true,
      },
      syncCenterAt: {
        type: 'Date',
        label: '上次同步管理中心時間',
      },
      syncStoreAt: {
        type: 'Date',
        label: '上次同步門市時間',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
    ragicStoreNodeId: {
      key: {
        ragicStoreNodeId: 1,
      },
    },
    updatedAt: {
      key: {
        '@@updatedAt': 1,
      },
    },
    syncStoreAt: {
      key: {
        syncStoreAt: 1,
      },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'type',
        'salePrice',
        'ragicCenterNodeId',
        'ragicStoreNodeId',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['name'],
          ['type'],
          ['lockPrice'],
          ['brandCode', 'brandRef'],
          ['supplierRef', 'orderCode'],
          ['unit'],
          ['origin'],
          ['note'],
          ['isContract'],
          ['orderFrom'],
          ['payType'],
          ['spec'],
          ['taxType'],
          ['basicAmount'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
          ['centerCost'],
          ['storeCost'],
          ['price'],
          ['staffPrice'],
          ['salePrice'],
          ['model'],
          ['color'],
          ['colorCode'],
          ['size'],
          ['material'],
          ['contour'],
          ['rimType'],
          ['templeLength'],
          ['lensTypeRef'],
          ['storageType'],
          ['sphereMax', 'sphereMin'],
          ['cylinderMax', 'cylinderMin'],
          ['axisMax', 'axisMin'],
          ['addMax', 'addMin'],
          ['SCMax', 'SCMin'],
          ['progressive'],
          ['diameter'],
          ['coating'],
          ['ultraviolet'],
          ['refractiveIndex'],
          ['blank'],
          ['blankShort'],
          ['design'],
          ['seriesRef'],
          ['radius'],
          ['replacementFrequency'],
          ['lensColor'],
          ['Ctype'],
          ['waterContent'],
          ['packingQuantity'],
          ['sphereMax', 'sphereMin'],
          ['cylinderMax', 'cylinderMin'],
          ['axisMax', 'axisMin'],
          ['addMax', 'addMin'],
          ['SCMax'],
          ['bonusFocalLength', 'bonusColoredLense'],
        ],
      },
    ],
  },
}

const stocks = {
  title: '商品庫存',
  data: {
    bundle: {
      stocks: { path: ['stocks'], quantity: 'many' },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'type',
        'salePrice',
        'centerCost',
        'storeCost',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['name'],
          ['type'],
          ['salePrice'],
          ['clPrice'],
          ['psPrice'],
          ['centerCost'],
          ['storeCost'],
        ],
      },
      {
        title: '庫存紀錄',
        name: 'tableEditor',
        path: 'stocks',
        exclude: ['productRef'],
        copyable: false,
        appendEmpty: false,
      },
    ],
    populationdepth: 2,
  },
}

const storeStocks = {
  title: '商品庫存查詢',
  data: {
    bundle: {
      stocks: { path: ['stocks'], quantity: 'many' },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'brandRef',
        'supplierRef',
        'salePrice',
        'model',
        'size',
        'colorCode',
        'material',
        'contour',
        'rimType',
        'color',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['salePrice', 'note'],
          ['model', 'colorCode'],
          ['material', 'size', 'rimType', 'color'],
          ['contour', 'templeLength'],
        ],
      },
      {
        title: '庫存紀錄',
        name: 'tableEditor',
        path: 'stocks',
        exclude: ['productRef'],
        copyable: false,
        appendEmpty: false,
      },
    ],
    populationdepth: 2,
  },
}

const typeA = {
  title: 'A類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'A',
        disabled: true,
      },
      unit: {
        creator: '支',
      },
    },
  },
  list: {
    query: {
      type: 'A',
    },
    ui: {
      fields: [
        'key',
        'name',
        'brandRef',
        'supplierRef',
        'centerCost',
        'storeCost',
        'price',
        'salePrice',
        'staffPrice',
        'model',
        'size',
        'colorCode',
        'material',
        'contour',
        'rimType',
        'color',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['model', 'colorCode'],
          ['material', 'size', 'rimType', 'color'],
          ['contour', 'templeLength'],
          ['isContract', 'orderFrom', 'payType', 'note'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const typeB = {
  title: 'B類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'B',
        disabled: true,
      },
    },
  },
  list: {
    query: {
      type: 'B',
    },
    ui: {
      fields: [
        'key',
        'name',
        'lensTypeRef',
        'supplierRef',
        'salePrice',
        'storageType',
        'note',
        'bonusFocalLength',
        'bonusColoredLense',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', 'lensTypeRef', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'blankShort', 'origin', 'unit'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['sphereMax', 'sphereMin', 'cylinderMax', 'cylinderMin', 'axisMax', 'axisMin'],
          ['addMax', 'addMin', 'SCMax', 'SCMin', 'storageType'],
          ['refractiveIndex', 'blank', 'design', 'coating', 'ultraviolet', 'diameter', 'progressive'],
          ['isContract', 'orderFrom', 'payType', 'note'],
          ['bonusFocalLength', 'bonusColoredLense'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const typeC = {
  title: 'C類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'C',
        disabled: true,
      },
    },
  },
  list: {
    query: {
      type: 'C',
    },
    ui: {
      fields: [
        'key',
        'name',
        'supplierRef',
        'salePrice',
        'staffPrice',
        'storeCost',
        'centerCost',
        'brandCode',
        'seriesRef',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', 'seriesRef', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit', 'diameter'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['sphereMax', 'sphereMin', 'cylinderMax', 'cylinderMin', 'axisMax', 'axisMin'],
          ['addMax', 'addMin', 'SCMax', 'SCMin'],
          ['radius', 'replacementFrequency', 'lensColor', 'Ctype', 'waterContent', 'packingQuantity'],
          ['isContract', 'orderFrom', 'payType', 'note'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const typeD = {
  title: 'D類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'D',
        disabled: true,
      },
    },
  },
  list: {
    query: {
      type: 'D',
    },
    ui: {
      fields: [
        'key',
        'name',
        'brandCode',
        'supplierRef',
        'salePrice',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['Dseries', 'spec', 'Dunit', 'Dpackage'],
          ['isContract', 'orderFrom', 'payType', 'note'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const typeE = {
  title: 'E類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'E',
        disabled: true,
      },
    },
  },
  list: {
    query: {
      type: 'E',
    },
    ui: {
      fields: [
        'key',
        'name',
        'brandCode',
        'supplierRef',
        'basicAmount',
        'centerCost',
        'storeCost',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['Etype', 'Ecode', 'Espec', 'basicAmount', 'taxType'],
          ['isContract', 'orderFrom', 'payType', 'note'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const typeF = {
  title: 'F類商品管理',
  viewSchema: {
    contents: {
      type: {
        creator: 'F',
        disabled: true,
      },
    },
  },
  list: {
    query: {
      type: 'F',
    },
    ui: {
      fields: [
        'key',
        'name',
        'brandCode',
        'supplierRef',
        'salePrice',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['centerCost', 'price', 'staffPrice', 'storeCost', 'salePrice', 'clPrice', 'psPrice'],
          ['isContract', 'orderFrom', 'payType', 'note', 'taxType'],
          ['ragicStoreNodeId', 'ragicCenterNodeId'],
        ],
      },
    ],
  },
}

const suppliers = {
  title: '商品資料管理',
  data: {
    bundle: {
      stocks: { path: ['stocks'], quantity: 'many' },
    },
  },
  list: {
    ui: {
      fields: [
        'key',
        'name',
        'brandRef',
        'supplierRef',
        'centerCost',
        'price',
        'salePrice',
        'model',
        'size',
        'colorCode',
        'material',
        'contour',
        'rimType',
        'color',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', { path: 'name', span: 4 }, 'type', 'lockPrice'],
          ['brandRef', 'brandCode', 'supplierRef', 'orderCode', 'origin', 'unit'],
          ['centerCost', 'price', 'salePrice', 'clPrice', 'psPrice'],
          ['model', 'colorCode'],
          ['material', 'size', 'rimType', 'color'],
          ['contour', 'templeLength'],
          ['isContract', 'orderFrom', 'payType', 'note'],
        ],
      },
      {
        title: '庫存紀錄',
        name: 'tableEditor',
        path: 'stocks',
        exclude: ['productRef'],
        copyable: false,
        appendEmpty: false,
      },
    ],
  },
  export: {
    fields: [
      'key',
      'name',
      'brandRef',
      'supplierRef',
      'centerCost',
      'price',
      'salePrice',
      'clPrice',
      'psPrice',
      'model',
      'size',
      'colorCode',
      'material',
      'contour',
      'rimType',
      'color',
    ],
    filter: async ({ me, modelviews }) => {
      const myId = path(['_id'], me)
      const supplierModel = modelviews.model('suppliers')
      let supplierRef = await supplierModel.select({ userRef: myId })
      supplierRef = map(path(['_id']), supplierRef)
      return { supplierRef: { $in: supplierRef } }
    },
  },
}

module.exports = {
  ...model,
  views: {
    stocks,
    storeStocks,
    typeA,
    typeB,
    typeC,
    typeD,
    typeE,
    typeF,
    suppliers,
  },
}
