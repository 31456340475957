const { clone, reduce, map, path } = require('coact/ramda')
const hasPath = require('coact/utils/hasPath')

const mergeConfig = require('coact/config/mergeConfig')
const tradingFormModule = require('coact/modules/tradingForm/main')
const stockRecorderModule = require('coact/modules/stockRecorder/main')

const purchasesReturnOrderMainSpec = {
  db: {
    orders: 'storePurchaseReturns',
    items: 'storePurchaseReturnItems',
    buyers: 'parties',
    sellers: 'parties',
    skus: 'products',
  },
  project: {
    skus: ['key'],
    buyers: ['name'],
    sellers: ['name'],
  },
  orderNo: { prefix: 'SIR', format: 'YYYYMMDD', digits: 3 },
  productReader: {
    getQueryCode: code => code.split('#')[0],
    moveToFirst: true,
  },
  view: 'main',
}

const purchasesReturnOrderCenterStaffersSpec = clone(purchasesReturnOrderMainSpec)
purchasesReturnOrderCenterStaffersSpec.view = 'centerStaffers'

const changeStockMainSpec = {
  db: {
    stocks: 'stocks',
    records: 'stockRecords',
    orders: 'storePurchaseReturns',
    items: 'storePurchaseReturnItems',
  },
  payload: {
    stock: {
      des: ({ doc, item }) => {
        if (doc.status === '新增') return null
        if (doc.status === '已寄出') return null
        if (doc.status === '取消') return null
        if (!hasPath(['skuRef'], item)) return null
        if (!(path(['skuRef', 'key'], item))) return null
        if (!hasPath(['sellerRef'], doc)) return null
        if (doc.sellerRef.storageMA === '不執行') return null
        return {
          key: `${doc.sellerRef.key}-${item.skuRef.key}`,
          warehousingRef: doc.sellerRef._id,
          productRef: item.skuRef._id,
        }
      },
      src: ({ doc, item }) => {
        if (doc.status === '新增') return null
        if (doc.status === '已寄出') return null
        if (doc.status === '取消') return null
        if (!hasPath(['skuRef'], item)) return null
        if (!(path(['skuRef', 'key'], item))) return null
        if (!hasPath(['buyerRef'], doc)) return null
        if (doc.buyerRef.storageMA === '不執行') return null
        return {
          key: `${doc.buyerRef.key}-${item.skuRef.key}`,
          warehousingRef: doc.buyerRef._id,
          productRef: item.skuRef._id,
        }
      },
    },
    record: {
      src: ({ doc, item }) => {
        if (!item) return null
        const date = doc.warehousingDate ? doc.warehousingDate : doc.date
        return { qty: -item.qty, date }
      },
      des: ({ doc, item }) => {
        if (!item) return null
        const date = doc.warehousingDate ? doc.warehousingDate : doc.date
        return { qty: item.qty, price: item.price, date }
      },
    },
  },
  refPath: {
    item: {
      record: 'stockRecordRefs',
    },
    record: {
      stock: 'stockRef',
      item: 'srcDBRef',
    },
  },
  view: 'main',
}

const changeStockCenterStaffersSpec = clone(changeStockMainSpec)
changeStockCenterStaffersSpec.view = 'centerStaffers'

const centerStaffersView = {
  title: '門市進貨退回單管理(管理中心)',
  edit: {
    noclone: {
      statementType: true,
      buyer: true,
      buyerStockAddress: true,
      seller: true,
      sellerStockAddress: true,
      status: true,
      payDate: true,
      payStatus: true,
      sysNote: true,
      agentKey: true,
      logisticsKey: true,
    },
    required: {
      'items.0.skuRef': false,
    },
    disabled: {
      'items.0.price': false,
      'items.0.supplier': false,
    },
    autofill: {
      '': [
        {
          src: 'buyerRef',
          fill: buyer => ({
            buyer,
            buyerStockAddress: buyer.address,
          }),
        },
        {
          src: 'sellerRef',
          fill: seller => ({
            seller,
            sellerStockAddress: seller.address,
          }),
        },
        {
          src: 'payStatus',
          fill: payStatus => ({
            payStatus,
            payDate: null,
          }),
        },
      ],
      'items.0': {
        src: 'skuRef',
        fill: (sku) => {
          if (sku) {
            const fillResult = {
              sku,
              key: sku.key,
              name: sku.name,
              price: sku.storeCost,
              paymentPrice: sku.centerCost,
              salePrice: sku.salePrice,
              unit: sku.unit,
              supplier: sku.supplierRef,
            }
            return fillResult
          }
          return { sku: null }
        },
      },
    },
    computer: {
      '': [
        (value) => {
          const amount = reduce(
            (total, { qty, price }) => (
              total + (qty * price)
            ),
            0,
            value.items || [],
          )

          const { priceType, taxRate, discount } = value
          const { date, payDate, sellerRef, buyerRef, status } = value
          const itemTaxRate = (priceType === '未稅價') ? taxRate : 1
          const afterTax = (priceType === '未稅價') ? Math.round(amount * taxRate) : Math.round(amount)
          const receivable = afterTax - discount
          const price = Math.round(receivable / taxRate)
          const tax = receivable - price
          const tempItems = []
          const items = []
          // generate nums String
          const counterObj = { }
          let previousMaxSubtotal = 0
          let maxIndex = 0
          let acumulatedDiscount = 0
          if (value.items) {
            value.items.forEach((element) => {
              const { unit, qty, subtotal } = element
              const item = element
              const thisDiscount = amount ? Math.round(discount
              * subtotal / amount / itemTaxRate) : 0
              if (subtotal > previousMaxSubtotal) {
                maxIndex += 1

                acumulatedDiscount += Math.round(discount
                * previousMaxSubtotal / amount / itemTaxRate)
                - thisDiscount

                previousMaxSubtotal = subtotal
                item.maxIndex = maxIndex
              }
              acumulatedDiscount += Math.round(discount * subtotal / amount / itemTaxRate)
              item.date = date
              item.payDate = payDate
              item.sellerRef = sellerRef
              item.seller = path(['name'], sellerRef)
              item.buyerRef = buyerRef
              item.buyer = path(['name'], buyerRef)
              item.status = status
              item.statementType = '門市進貨退回單'
              item.discount = thisDiscount
              item.receivableSubtotal = subtotal - thisDiscount
              tempItems.push(item)
              if (unit) {
                if (counterObj[unit]) counterObj[unit] += qty
                else counterObj[unit] = qty
              } else if (counterObj['個']) counterObj['個'] += qty
              else counterObj['個'] = qty
            })
            const residualDiscount = Math.round(discount / itemTaxRate) - acumulatedDiscount
            tempItems.forEach((element) => {
              const item = element
              if (element.maxIndex === maxIndex) {
                item.discount = residualDiscount
                item.receivableSubtotal = element.subtotal - residualDiscount
              }
              items.push(item)
            })
          }
          let summary = '共:'
          Object.entries(counterObj).forEach(([unit, num]) => {
            summary += (num) && ` ${num.toString()} ${unit}`
          })

          return {
            amount,
            tax,
            price,
            afterTax,
            receivable,
            summary,
            items,
          }
        },
      ],
      'items.0': [
        ({ qty, price }) => {
          if (Number.isNaN(qty * price)) return { subtotal: null }
          return { subtotal: qty * price }
        },
      ],
    },
    creator: {
      statementType: 'purchaseReturn',
      payStatus: '正常',
      taxRate: 1.05,
      priceType: '未稅價',
      payDate: '$$NOW',
      printStatus: '未列印',
    },
    loaders: {
      'storePurchaseReturns:centerStaffers': purchaseReturn => ({
        sellerRef: purchaseReturn.sellerRef,
        buyerRef: null,
        note: purchaseReturn.note,
        priceType: purchaseReturn.priceType,
        items: purchaseReturn.items,
      }),
      'storePurchases:centerStaffers': purchase => ({
        sellerRef: purchase.sellerRef,
        buyerRef: null,
        items: purchase.items,
        priceType: purchase.priceType,
        note: purchase.note,
      }),
    },
    actions: {
      saveAndCopy: { authChecker: (viewAuth, userAuth) => path(['authority', 'streams', 'data', 'get'], userAuth) },
      print: {
        text: '列印',
        hotKey: 'ctrl+p',
        effect: { name: 'saveAndPrint' },
      },
      arrive: {
        text: '到貨',
        effect: { name: 'arrive' },
      },
      downloadItems: {
        text: '下載',
        effect: { name: 'downloadItems' },
      },
      printBarcode: {
        text: '列印條碼',
        effect: { name: 'printBarcode' },
      },
    },
    effect: {
      saveAndPrint: (s, ctx) => {
        const printStatus = path(['doc', 'printStatus'], ctx)
        if (printStatus !== '已列印') {
          ctx.editPath({ path: ['printStatus'], value: '已列印' })
          ctx.saveDoc()
          window.print()
        } else {
          // eslint-disable-next-line no-alert
          const result = window.confirm('已列印過，是否再印?')
          if (result) {
            ctx.saveDoc()
            window.print()
          }
        }
      },
      arrive: (s, ctx) => {
        const isDataLoaded = () => {
          const state = ctx.$edit.select.edit(ctx.store.getState())
          return state.phase === ctx.$edit.phase.dataLoaded
        }

        const intervalId = setInterval(() => {
          if (isDataLoaded()) {
            clearInterval(intervalId)
            ctx.editPath({ path: ['warehousingDate'], value: new Date() })
            ctx.editPath({ path: ['status'], value: '到貨' })
            ctx.saveDoc()
          }
        }, 100)
      },
      downloadItems: (s, ctx) => {
        const tradeKey = path(['doc', 'key'], ctx)
        const query = { orderRef: { $in: [`ObjectId(${path(['doc', '_id'], ctx)})`] } }
        const url = `/api/stream/data?feature=storePurchaseReturnItems&filename=${tradeKey}&limit=50&populationdepth=1&populations=%2A&query=${JSON.stringify(query)}&skip=0&sort={"@@createdAt":-1}&type=xlsx`
        window.open(url)
      },
      printBarcode: (s, ctx) => {
        const id = path(['doc', '_id'], ctx)
        const tradeKey = path(['doc', 'key'], ctx)
        const url = `/api/route/printBarcode?id=${id}&tradeKey=${tradeKey}&modelName=storePurchaseReturns`
        window.open(url)
      },
    },
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'payDate', 'status', 'payStatus'], // 'create',
          ['buyerRef', 'buyer.phone', 'buyer.FAX', 'buyer.CID', 'printStatus'],
          [{ path: 'buyer.address', c: 1, span: 2 }, { path: 'buyerStockAddress', c: 1, span: 2 }],
          ['sellerRef', 'seller.phone', 'seller.FAX', 'seller.CID', 'warehousingDate'],
          [{ path: 'seller.address', c: 1, span: 2 }, { path: 'sellerStockAddress', c: 1, span: 2 }],
          ['amount', 'discount', 'priceType', 'taxRate', 'receivable'],
          ['agentKey', 'logisticsKey', 'price', 'tax', 'afterTax'],
          ['cost', 'profit', 'summary', { path: 'note', span: 1 }, { path: 'sysNote', span: 1 }],
        ],
      },
      items: {
        name: 'tableEditor',
        copyable: false,
        path: 'items',
        fields: ['skuRef', 'qty', 'name', 'unit', 'price', 'paymentPrice', 'subtotal', 'discount', 'receivableSubtotal', 'supplier', 'sysNote', 'note'],
        deletable: true,
      },
    },
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        'key',
        'status',
        'agentKey',
        'sellerRef',
        'buyerRef',
        'date',
        'payDate',
        'summary',
        'receivable',
        'tax',
        'price',
        'note',
        'payStatus',
        'printStatus',
        'warehousingDate',
      ],
    },
    populations: 'parties',
  },
  export: {
    fields: [
      'key',
      'status',
      'agentKey',
      'sellerRef',
      'buyerRef',
      'date',
      'payDate',
      'summary',
      'receivable',
      'tax',
      'price',
      'note',
      'payStatus',
    ],
  },
}

const mainView = clone(centerStaffersView)
mainView.title = '門市進貨退回單管理'

mainView.edit.required['items.0.skuRef'] = true
// mainView.edit.required.agentKey = true
mainView.edit.disabled['items.0.price'] = true
mainView.edit.disabled['items.0.supplier'] = true
mainView.edit.ui.items.fields = ['skuRef', 'qty', 'name', 'unit', 'price', 'subtotal', 'discount', 'receivableSubtotal', 'supplier', 'sysNote', 'note']
mainView.edit.actions.saveAndCopy.authChecker = () => null
mainView.export.filter = async ({ me, modelviews }) => {
  const myId = path(['_id'], me)
  const partyModel = modelviews.model('parties')
  let partyRef = await partyModel.select({ userRef: myId })
  partyRef = map(path(['_id']), partyRef)
  return { sellerRef: { $in: partyRef } }
}
centerStaffersView.edit.actions.transTradeform = {
  text: '轉門市進貨單',
  action: 'saveAndCreate',
  effect: { name: 'saveAndCreate', spec: { copy: true, to: 'storePurchases:centerStaffers' } },
}
centerStaffersView.edit.actions.transSaleServiceform = {
  text: '轉售服單',
  action: 'saveAndCreate',
  effect: { name: 'saveAndCreate', spec: { copy: true, to: 'saleReturns:main' } },
}
centerStaffersView.edit.actions.reloadItems = {
  text: '更新價格',
  effect: { name: 'reloadItems' },
}
centerStaffersView.edit.effect.reloadItems = (s, ctx) => {
  const items = path(['doc', 'items'], ctx)
  if (items.length > 0) {
    let i = 0
    items.forEach((item) => {
      // ctx.editPath({ path: ['items', i, 'price'], value: item.sku.storeCost })
      if (item.key !== null && item.key !== '' && item.key !== undefined) {
        ctx.editPath({ path: ['items', i, 'price'], value: item.skuRef.storeCost })
        ctx.editPath({ path: ['items', i, 'paymentPrice'], value: item.skuRef.centerCost })
        // ctx.resetPath({ path: ['items', i], value: item })
      }
      i += 1
    })
  }
  ctx.saveDoc()
}

const itemView = {
  title: '門市進貨退回單品項管理',
  edit: {
    ui: {
      items: {
        name: 'formEditor',
        fields: [
          ['skuRef'],
          ['qty'],
          ['name'],
          ['unit'],
          ['price'],
          ['subtotal'],
          ['discount'],
          ['receivableSubtotal'],
          ['supplier'],
          ['sysNote'],
          ['note'],
        ],
      },
    },
  },
  list: {
    actions: {
      exportText: {
        text: '文字',
        effect: { run: 'first', name: 'exportData', type: 'csv' },
        // props: highEmphasis,
        icon: 'CloudDownload',
        authChecker: (viewAuth, userAuth) => (
          path(['authority', 'streams', 'data', 'get'], userAuth)
        ),
      },
    },
    ui: {
      fields: [
        { path: 'orderRef.key', model: 'storePurchaseReturns', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'storePurchaseReturns', queryPath: 'orderRef' },
        { path: 'orderRef.payDate', model: 'storePurchaseReturns', queryPath: 'orderRef' },
        { path: 'orderRef.buyerRef', model: 'storePurchaseReturns', queryPath: 'orderRef' },
        'skuRef',
        'qty',
        'name',
        'unit',
        'price',
        'subtotal',
        'discount',
        'receivableSubtotal',
        'supplier',
        'sysNote',
        'note',
      ],
    },
    populations: '*',
    populationdepth: 2,
  },
  export: {
    fields: [
      'key',
      'name',
      'qty',
      'salePrice',
      'subtotal',
      'supplier.name',
      'orderRef.key',
      'statementType',
      'seller',
      'buyer',
      'date',
      'payDate',
      'status',
      'orderRef.taxRate',
      'orderRef.priceType',
    ],
  },
}

const models = {
  storePurchaseReturns: {
    title: '門市進貨退回單管理',
    schema: {
      type: 'Object',
      contents: {
        ragicField: {
          label: 'Ragic Id',
          type: 'Number',
          ragicField: '_ragicId',
          disabled: true,
        },
        key: {
          label: '進退單號',
          disabled: true,
        },
        statementType: {
          type: 'String',
          label: '單據類型',
          disabled: true,
        },
        status: {
          label: '狀態',
          options: ['新增', '已寄出', '到貨', '取消'],
        },
        printStatus: {
          type: 'String',
          label: '列印狀態',
          disabled: true,
          options: ['未列印', '已列印'],
        },
        agentKey: {
          type: 'String',
          label: '廠商銷貨單號',
          // required: true,
        },
        logisticsKey: {
          type: 'String',
          label: '物流編號',
        },
        // isMerge
        date: {
          label: '進退日期',
          format: 'YYYY/MM/DD',
        },
        warehousingDate: {
          type: 'Date',
          label: '入庫日期',
          format: 'YYYY/MM/DD',
        },
        payDate: {
          type: 'Date',
          label: '結帳日期',
          format: 'YYYY/MM/DD',
        },
        create: {
          label: '建立單位',
          type: 'ObjectId',
          reference: 'stores',
          refKeys: ['name'],
          input: 'select',
        },
        payStatus: {
          type: 'String',
          label: '帳款狀態',
          input: 'select',
          options: ['正常', '延後處理'],
        },
        buyerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '進貨單位',
          initFocus: true,
          optionsKey: 'buyerParties',
        },
        buyer: {
          contents: {
            phone: {
              type: 'String',
              label: '電話',
              disabled: true,
            },
            FAX: {
              type: 'String',
              label: '傳真',
              disabled: true,
            },
            address: {
              type: 'String',
              label: '地址',
              disabled: true,
            },
            CID: {
              type: 'String',
              label: '進貨單位統編',
              disabled: true,
            },
          },
        },
        buyerStockAddress: {
          type: 'String',
          label: '送貨地址',
          disabled: true,
        },
        sellerRef: {
          label: '供貨單位',
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          optionsKey: 'sellerParties',
        },
        seller: {
          contents: {
            phone: {
              label: '供貨電話',
              type: 'String',
              disabled: true,
            },
            FAX: {
              label: '供貨傳真',
              type: 'String',
              disabled: true,
            },
            address: {
              label: '供貨連絡地址',
              type: 'String',
              disabled: true,
            },
            CID: {
              label: '供貨單位統編',
              type: 'String',
              disabled: true,
            },
          },
        },
        sellerStockAddress: {
          label: '出貨地址',
          type: 'String',
          disabled: true,
        },
        amount: {
          label: '合計金額',
          type: 'Number',
          disabled: true,
        },
        taxRate: {
          label: '稅率',
          type: 'Number',
        },
        priceType: {
          label: '價格別',
          type: 'String',
          input: 'select',
          options: ['未稅價', '含稅價'],
        },
        afterTax: {
          label: '含稅金額',
          type: 'Number',
          disabled: true,
        },
        discount: {
          label: '折讓金額',
          type: 'Number',
        },
        receivable: {
          label: '應付金額',
          type: 'Number',
          disabled: true,
        },
        price: {
          label: '商品金額',
          type: 'Number',
          disabled: true,
        },
        tax: {
          label: '稅金',
          type: 'Number',
          disabled: true,
        },
        note: {
          label: '附註',
          type: 'String',
        },
        sysNote: {
          label: '系統備註',
          type: 'String',
          disabled: true,
        },
        summary: {
          label: '商品總數',
          disabled: true,
          type: 'String',
        },
        outStatus: {
          label: '出庫狀態',
          type: 'String',
        },
        inStatus: {
          label: '入庫狀態',
          type: 'String',
        },
        outType: {
          label: '出庫別',
          type: 'String',
        },
        inType: {
          label: '入庫別',
          type: 'String',
        },
        cost: {
          label: '商品成本',
          type: 'Number',
          disabled: true,
        },
        profit: {
          label: '利潤',
          type: 'Number',
          disabled: true,
        },
      },
    },
    views: {
      main: mainView,
      centerStaffers: centerStaffersView,
    },
    import: {
      main: {
        search: { rebuildRef: null },
      },
      centerStaffers: {
        search: { rebuildRef: null },
      },
    },
  },
  storePurchaseReturnItems: {
    title: '門市退貨項目',
    schema: {
      contents: {
        skuRef: {
          type: 'ObjectId',
          label: '貨品條碼',
          reference: 'products',
          refKeys: ['key'],
          input: 'select',
          width: 200,
        },
        key: { type: 'String', label: '貨品條碼', width: 200 },
        unit: { type: 'String', label: '單位', width: 60 },
        price: { type: 'Number', label: '計價', width: 60 },
        paymentPrice: { type: 'Number', label: '管理中心成本', width: 60 },
        salePrice: { type: 'Number', label: '外價', width: 60 },
        discount: { type: 'Number', label: '折讓金額', width: 60, creator: 0, disabled: true },
        receivableSubtotal: { type: 'Number', label: '應收小計', width: 60, disabled: true },
        supplier: {
          type: 'ObjectId',
          reference: 'suppliers',
          refKeys: ['name'],
          label: '供應商',
          width: 100,
        },
        sysNote: {
          label: '系統備註',
          type: 'String',
          width: 150,
          disabled: true,
        },
        stockRecordRefs: {
          type: 'Array',
          contents: [{
            type: 'ObjectId',
            label: '修改庫存紀錄',
            reference: 'stockRecords',
            refKeys: ['stockRef'],
          }],
        },
        statementType: {
          type: 'String',
          label: '單據類型',
          disabled: true,
        },
        sellerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '銷貨單位',
          disabled: true,
        },
        buyerRef: {
          type: 'ObjectId',
          reference: 'parties',
          refKeys: ['name'],
          input: 'select',
          label: '進貨單位',
          disabled: true,
        },
        seller: {
          type: 'String',
          label: '銷貨單位',
        },
        buyer: {
          type: 'String',
          label: '進貨單位',
        },
        date: {
          type: 'Date',
          label: '進貨日期',
          format: 'YYYY/MM/DD',
          disabled: true,
        },
        payDate: {
          type: 'Date',
          label: '結帳日期',
          format: 'YYYY/MM/DD',
          disabled: true,
        },
        status: {
          type: 'String',
          label: '狀態',
          options: ['新增', '已寄出', '到貨', '取消'],
          disabled: true,
        },
      },
    },
    views: {
      main: itemView,
      centerStaffers: itemView,
    },
    list: {
      ui: {
        fields: ['key', 'name', 'qty', 'subtotal', 'supplier', 'sellerRef', 'buyerRef', 'date', 'payDate', 'status', 'sysNote', 'note'],
      },
    },
    edit: {
      ui: [
        {
          name: 'formEditor',
          cols: { default: 150, 1: 300 },
          fields: [
            ['skuRef'],
            ['key', 'name'],
            ['qty', 'unit'],
            ['price', 'salePrice'],
            ['supplier'],
            ['sysNote'],
          ],
        },
      ],
    },
    export: {
      fields: [
        'key',
        'name',
        'qty',
        'salePrice',
        'subtotal',
        'supplier.name',
        'orderRef.key',
        'statementType',
        'seller',
        'buyer',
        'date',
        'payDate',
        'status',
        'orderRef.taxRate',
        'orderRef.priceType',
      ],
      populations: '*',
      populationdepth: 2,
    },
  },
}

const fullModels = reduce(
  mergeConfig,
  { },
  [
    tradingFormModule(purchasesReturnOrderMainSpec),
    tradingFormModule(purchasesReturnOrderCenterStaffersSpec),
    stockRecorderModule(changeStockMainSpec),
    stockRecorderModule(changeStockCenterStaffersSpec),
    { models },
  ],
)

module.exports = fullModels
