const model = {
  title: '承件單資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000522,
        label: '承件單號',
        type: 'String',
        unique: true,
      },
      time: {
        ragicField: 1000523,
        label: '承件時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      status: {
        ragicField: 1000531,
        label: '承件單狀態',
        type: 'String',
        input: 'select',
        options: ['新增', '已輸入', '已結單', '作廢'],
      },
      pickUpMethod: {
        ragicField: 1000944,
        label: '取件方式',
        type: 'String',
        input: 'select',
        options: ['一般', '郵寄', '非郵寄'],
      },
      deliveryNum: {
        ragicField: 1000945,
        label: '郵寄編號',
        type: 'String',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '承件門市', ragicField: 1000001 },
        },
      },
      total: {
        ragicField: 1000524,
        label: '總計金額',
        type: 'Number',
      },
      pdtTotal: {
        ragicField: 1000625,
        label: '商品金額',
        type: 'Number',
      },
      discountTotal: {
        ragicField: 1000626,
        label: '折讓金額',
        type: 'Number',
      },
      maker: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '加工人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '加工人員', ragicField: 1000529 },
        },
      },
      assist: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '行政人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '行政人員', ragicField: 1000710 },
        },
      },
      // thisPaid: {
      //   ragicField: 1000602,
      //   label: '此次付款',
      //   type: 'Number',
      // },
      paid: {
        ragicField: 1000526,
        label: '已付金額',
        type: 'Number',
      },
      unpaid: {
        ragicField: 1000528,
        label: '未付金額',
        type: 'Number',
      },
      optometrist: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '驗光人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '驗光人員', ragicField: 1000530 },
        },
      },
      salesman: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '銷售人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '銷售人員', ragicField: 1000711 },
        },
      },
      deliverman: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '交件人員', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '交件人員', ragicField: 1000712 },
        },
      },
      drKey: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報編號', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      // unlinkCust: {
      //   ragicField: 1000645,
      //   label: '移除客戶',
      //   type: 'String',
      // },
      pickUpStatus: {
        ragicField: 1000646,
        label: '取件狀態',
        type: 'String',
        input: 'select',
        options: ['未取件', '已取件'],
      },
      pickUpTime: {
        ragicField: 1000673,
        label: '取件時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      payStatus: {
        ragicField: 1000666,
        label: '付款狀態',
        type: 'String',
        input: 'select',
        options: ['未付清', '已付'],
      },
      updateTime: {
        ragicField: 1000647,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      // allPickUp: {
      //   ragicField: 1000648,
      //   label: '全店已取件',
      //   type: 'String',
      // },
      // updatePayStatus: {
      //   ragicField: 1000667,
      //   label: '更新付款狀態',
      //   type: 'String',
      // },
      saleReturn: {
        ragicField: 1000679,
        label: '銷貨退回',
        type: 'Number',
      },
      returnCash: {
        ragicField: 1000683,
        label: '退回金額',
        type: 'String',
      },
      // closeOrder: {
      //   ragicField: 1000685,
      //   label: '關閉承件單',
      //   type: 'String',
      // },
      // updateStatus: {
      //   ragicField: 1000700,
      //   label: '全店更新狀態',
      //   type: 'String',
      // },
      paidTime: {
        ragicField: 1000701,
        label: '付清時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
      // fillPickTime: {
      //   ragicField: 1000702,
      //   label: '全店填入取件時間',
      //   type: 'Date',
      //   format: 'YYYY-MM-DD',
      // },
      saleInfo: {
        ragicField: 1000705,
        label: '銷售資訊',
        type: 'String',
      },
      custName: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '客戶編號', reference: 'customers', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '客戶編號', ragicField: 1000508 },
        },
      },
      updatedAt: {
        ragicField: 1001042,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
    subtables: [
      {
        modelName: 'customers:retailOrders',
        ragicField: 1000508,
        refFieldName: 'custName',
      },
      {
        modelName: 'prescripts',
        ragicField: 1000532,
        refFieldName: 'order',
      },
      {
        modelName: 'retailSales',
        ragicField: 1000550,
        refFieldName: 'orderKey',
      },
      {
        modelName: 'invoices',
        ragicField: 1000573,
        refFieldName: 'retailOrder',
      },
      {
        modelName: 'retailSaleReturns',
        ragicField: 1000674,
        refFieldName: 'orderKey',
      },
      {
        modelName: 'creditCards',
        ragicField: 1000726,
        refFieldName: 'orderKey',
      },
    ],
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
    updatedat: {
      key: {
        updatedAt: 1,
      },
    },
  },
}

const main = {
  title: '承件單資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'time',
        'status',
        'store.name',
        'total',
        'pdtTotal',
        'discountTotal',
        'maker.name',
        'thisPaid',
        'paid',
        'unpaid',
        'optometrist.name',
        'drKey.key',
        'pickUpStatus',
        'pickUpTime',
        'payStatus',
        'updateTime',
        'saleReturn',
        'returnCash',
        'paidTime',
        'saleInfo',
        'custName.key',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['time'],
          ['status'],
          ['store.id'],
          ['total'],
          ['pdtTotal'],
          ['discountTotal'],
          ['maker.id'],
          ['thisPaid'],
          ['paid'],
          ['unpaid'],
          ['optometrist.id'],
          ['drKey.id'],
          ['pickUpStatus'],
          ['pickUpTime'],
          ['payStatus'],
          ['updateTime'],
          ['saleReturn'],
          ['returnCash'],
          ['paidTime'],
          ['saleInfo'],
          ['custName.id'],
        ],
      },
    ],
  },
}

const buildBundlePair = (bundleModel) => {
  const bundlePair = {
    bundle: { },
    editor: {
      title: bundleModel,
      name: 'tableEditor',
      path: bundleModel,
      exclude: ['retailOrder'],
    },
  }
  bundlePair.bundle[bundleModel] = { path: [bundleModel], quantity: 'many' }
  return bundlePair
}

const ui = bundleModel => [
  {
    name: 'formEditor',
    fields: [
      ['key'],
      ['time'],
      ['status'],
      ['store.id'],
      ['total'],
      ['pdtTotal'],
      ['discountTotal'],
      ['maker.id'],
      ['thisPaid'],
      ['paid'],
      ['unpaid'],
      ['optometrist.id'],
      ['drKey.id'],
      ['pickUpStatus'],
      ['pickUpTime'],
      ['payStatus'],
      ['updateTime'],
      ['saleReturn'],
      ['returnCash'],
      ['paidTime'],
      ['saleInfo'],
      ['custName.id'],
    ],
  },
  buildBundlePair(bundleModel).editor,
]

// const customers = {
//   title: '承件單客戶管理',
//   data: {
//     bundle: buildBundlePair('customers').bundle,
//   },
//   edit: { ui: ui('customers') },
// }

const prescripts = {
  title: '承件單處方管理',
  data: {
    bundle: buildBundlePair('prescripts').bundle,
  },
  edit: { ui: ui('prescripts') },
}

const retailSales = {
  title: '承件單銷售資料管理',
  data: {
    bundle: buildBundlePair('retailSales').bundle,
  },
  edit: { ui: ui('retailSales') },
}

const invoices = {
  title: '承件單發票管理',
  data: {
    bundle: buildBundlePair('invoices').bundle,
  },
  edit: { ui: ui('invoices') },
}

const retailSaleReturns = {
  title: '承件單銷退資料管理',
  data: {
    bundle: buildBundlePair('retailSaleReturns').bundle,
  },
  edit: { ui: ui('retailSaleReturns') },
}

const creditCards = {
  title: '承件單刷卡管理',
  data: {
    bundle: buildBundlePair('creditCards').bundle,
  },
  edit: { ui: ui('creditCards') },
}

module.exports = {
  ...model,
  views: {
    main,
    // customers,
    prescripts,
    retailSales,
    invoices,
    retailSaleReturns,
    creditCards,
  },
}
