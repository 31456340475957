const model = {
  title: '刷卡資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000726,
        label: '刷卡編號',
        type: 'String',
        unique: true,
      },
      machineNo: {
        ragicField: 1000727,
        label: '端末機代號',
        type: 'String',
      },
      cardNo: {
        ragicField: 1000728,
        label: '卡號',
        type: 'String',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '刷卡門市', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '刷卡門市', ragicField: 1000001 },
        },
      },
      type: {
        ragicField: 1000729,
        label: '交易類別',
        type: 'String',
        input: 'select',
        options: ['銷售', '退貨', '分期'],
      },
      cardType: {
        ragicField: 1000730,
        label: '卡別',
        type: 'String',
        input: 'select',
        options: ['Visa', 'MasterCard', 'JCB', 'UCard', 'AE', 'Diners', 'CUP'],
      },
      approvalCode: {
        ragicField: 1000731,
        label: '授權碼',
        type: 'String',
      },
      time: {
        ragicField: 1000732,
        label: '刷卡時間',
        type: 'Date',
        input: 'text',
        format: 'YYYY-MM-DD HH:mm:ss',
      },
      traceNo: {
        ragicField: 1000734,
        label: '調閱編號',
        type: 'String',
      },
      amount: {
        ragicField: 1000738,
        label: '刷卡金額',
        type: 'Number',
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      dailyReport: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '營業日報編號', reference: 'dailyReports', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '營業日報編號', ragicField: 1000631 },
        },
      },
      invoice: {
        ragicField: 1000736,
        label: '發票號碼',
        type: 'String',
      },
      note: {
        ragicField: 1000744,
        label: '註記',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001044,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
}

const main = {
  title: '刷卡資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'machineNo',
        'cardNo',
        'store.name',
        'type',
        'cardType',
        'approvalCode',
        'time',
        'traceNo',
        'amount',
        'retailOrder.key',
        'invoice',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['machineNo'],
          ['cardNo'],
          ['store.id'],
          ['type'],
          ['cardType'],
          ['approvalCode'],
          ['time'],
          ['traceNo'],
          ['amount'],
          ['retailOrder.id'],
          ['dailyReport.id'],
          ['invoice'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
