module.exports = {
  title: '科目',
  schema: {
    type: 'Object',
    contents: {
      accountCode: {
        type: 'String',
        label: '科目代碼',
        unique: true,
      },
      account: {
        type: 'String',
        label: '科目',
      },
    },
  },
  list: {
    ui: {
      fields: [
        'accountCode',
        'account',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 150, 1: 300 },
        fields: [
          [{ path: 'name', span: 2 }],
          ['accountCode'],
          ['account'],
        ],
      },
    ],
  },
}
