const R = require('coact/ramda')
const mergeConfig = require('coact/config/mergeConfig')

const basicModels = {
  stores: require('./stores'),
  staffers: require('./staffers'),
  suppliers: require('./suppliers'),
  parties: require('./parties'),
  brands: require('./brands'),
  typeAColors: require('./typeAColors'),
  lensTypes: require('./lensTypes'),
  serieses: require('./serieses'),
  products: require('./products'),
  stocks: require('./stocks'),
  stockRecords: require('./stockRecords'),
  accounts: require('./accounts'),
  paymentMethods: require('./paymentMethods'),
  entries: require('./entries'),
  itemBarcodes: require('./itemBarcodes'),
  payments: require('./payments'),
  recStatements: require('./recStatements'),
  payStatements: require('./payStatements'),
  customers: require('./customers'),
  prescripts: require('./prescripts'),
  retailOrders: require('./retailOrders'),
  retailSales: require('./retailSales'),
  creditCards: require('./creditCards'),
  dailyReports: require('./dailyReports'),
  miscellaneous: require('./miscellaneous'),
  retailSaleReturns: require('./retailSaleReturns'),
  machines: require('./machines'),
  packs: require('./packs'),
  invoices: require('./invoices'),
  invoiceDailyReports: require('./invoiceDailyReports'),
  taiwanpays: require('./taiwanpays'),
}

const { models: mergedModels } = R.reduce(
  mergeConfig,
  { },
  [
    { models: basicModels },
    require('./purchases'),
    require('./storePurchases'),
    require('./purchaseReturns'),
    require('./storePurchaseReturns'),
    require('./sales'),
    require('./saleReturns'),
    { models: require('../storeStatements').models },
  ],
)

module.exports = mergedModels
