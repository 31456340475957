module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '承件單號' },
    store: { type: 'String', label: '門市' },
    time: { type: 'Date', label: '承件時間' },
    status: { type: 'String', label: '狀態' },
    productInfo: { type: 'String', label: '商品資訊' },
    optometrist: { type: 'String', label: '驗光人員' },
    customer: { type: 'String', label: '客戶姓名' },
  },
}
