const model = {
  title: '營業日報資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000631,
        label: '營業日報編號',
        type: 'String',
      },
      saleCreditCard: {
        ragicField: 1000739,
        label: '刷卡銷售',
        type: 'Number',
      },
      saleTaiwanpay: {
        ragicField: 1000990,
        label: '台灣Pay銷售',
        type: 'Number',
      },
      store: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '門市_id', reference: 'stores', refKeys: ['name'], view: 'form' },
          name: { type: 'String', label: '門市', ragicField: 1000001 },
        },
      },
      refundCreditCard: {
        ragicField: 1000740,
        label: '刷卡退貨',
        type: 'Number',
      },
      saleTaiwanpayCard: {
        ragicField: 1001015,
        label: '信用卡收入',
        type: 'Number',
      },
      saleTaiwanpayCardSystem: {
        ragicField: 1001048,
        label: '系統信用卡銷售',
        type: 'Number',
      },
      income: {
        ragicField: 1000640,
        label: '實收',
        type: 'Number',
      },
      status: {
        ragicField: 1000643,
        label: '報表狀態',
        type: 'String',
        options: ['新增', '已結帳'],
        input: 'select',
      },
      creditCardFee: {
        ragicField: 1000745,
        label: '刷卡手續費總計',
        type: 'Number',
      },
      saleTaiwanpayBank: {
        ragicField: 1001016,
        label: '金融卡收入',
        type: 'Number',
      },
      saleTaiwanpayBankSystem: {
        ragicField: 1001049,
        label: '系統金融卡銷售',
        type: 'Number',
      },
      electronicPaymentFee: {
        ragicField: 1001025,
        label: '電子支付手續費總計',
        type: 'Number',
      },
      date: {
        ragicField: 1000644,
        label: '報表日期',
        type: 'Date',
      },
      creditCardAmount: {
        ragicField: 1000746,
        label: '刷卡收入總計',
        type: 'Number',
      },
      taiwanpaySaleDiff: {
        ragicField: 1001027,
        label: '台灣Pay銷售差額',
        type: 'Number',
      },
      numberOfVisitors: {
        ragicField: 1000706,
        label: '來客數',
        type: 'Number',
      },
      creditCardsDiff: {
        ragicField: 1001023,
        label: '刷卡差額',
        type: 'Number',
      },
      refundTaiwanpay: {
        ragicField: 1000991,
        label: '台灣Pay退貨',
        type: 'Number',
      },
      newPerformance: {
        ragicField: 1000632,
        label: '新增業績',
        type: 'Number',
      },
      cashIncome: {
        ragicField: 1000633,
        label: '收現金',
        type: 'Number',
      },
      refundTaiwanpayCard: {
        ragicField: 1001017,
        label: '信用卡退貨',
        type: 'Number',
      },
      refundTaiwanpayCardSys: {
        ragicField: 1001050,
        label: '系統信用卡退貨',
        type: 'Number',
      },
      refundAmount: {
        ragicField: 1000635,
        label: '銷貨退回',
        type: 'Number',
      },
      cashRefund: {
        ragicField: 1000684,
        label: '銷退現金',
        type: 'Number',
      },
      refundTaiwanpayBank: {
        ragicField: 1001018,
        label: '金融卡退貨',
        type: 'Number',
      },
      refundTaiwanpayBankSys: {
        ragicField: 1001051,
        label: '系統金融卡退貨',
        type: 'Number',
      },
      performanceAmount: {
        ragicField: 1000642,
        label: '總業績',
        type: 'Number',
      },
      cashRefundByInvoiceRevoke: {
        ragicField: 1000715,
        label: '發票作廢退現金',
        type: 'Number',
      },
      refundTaiwanpayDiff: {
        ragicField: 1001028,
        label: '台灣Pay退貨差額',
        type: 'Number',
      },
      todaysRetailOrderIncome: {
        ragicField: 1000634,
        label: '今日承件收款',
        type: 'Number',
      },
      cash: {
        ragicField: 1000931,
        label: '現金',
        type: 'Number',
      },
      taiwanpayFee: {
        ragicField: 1001009,
        label: '台灣Pay手續費總計',
        type: 'Number',
      },
      pastsRetailOrderIncome: {
        ragicField: 1000637,
        label: '往日承件收款',
        type: 'Number',
      },
      transferIncome: {
        ragicField: 1000932,
        label: '匯款金額',
        type: 'Number',
      },
      taiwanpayAmount: {
        ragicField: 1001010,
        label: '台灣Pay收入總計',
        type: 'Number',
      },
      miscellaneousExpenditure: {
        ragicField: 1000638,
        label: '其他支出',
        type: 'Number',
      },
      transferNote: {
        ragicField: 1000933,
        label: '匯款說明',
        type: 'String',
      },
      taiwanpayDiff: {
        ragicField: 1001024,
        label: '台灣Pay差額',
        type: 'Number',
      },
      miscellaneousIncome: {
        ragicField: 1000921,
        label: '其他收入',
        type: 'Number',
      },
      difference: {
        ragicField: 1000641,
        label: '差額',
        type: 'Number',
      },
      checkoutStaffer: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '結帳人員_id', reference: 'staffers', refKeys: ['key'], view: 'form' },
          name: { type: 'String', label: '結帳人員', ragicField: 1000713 },
        },
      },
      systemNote: {
        ragicField: 1000668,
        label: '系統紀錄',
        type: 'String',
      },
      updatedAt: {
        ragicField: 1001047,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: false,
    },
  },
}

const main = {
  title: '營業日報資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'status',
        'store.id',
        'date',
        'newPerformance',
        'performanceAmount',
        'cashIncome',
        'refundAmount',
        'cashRefund',
        'miscellaneousExpenditure',
        'miscellaneousIncome',
        'transferIncome',
        'income',
        'difference',
        'todaysRetailOrderIncome',
        'pastsRetailOrderIncome',
        'numberOfVisitors',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', 'store.id', 'status', 'date', 'numberOfVisitors'],
          ['newPerformance', 'refundAmount', 'performanceAmount', 'todaysRetailOrderIncome', 'pastsRetailOrderIncome'],
          ['miscellaneousExpenditure', 'miscellaneousIncome'],
          ['saleCreditCard', 'refundCreditCard', 'creditCardFee', 'creditCardAmount', 'creditCardsDiff'],
          ['cashIncome', 'cashRefund', 'cashRefundByInvoiceRevoke', 'cash'],
          ['transferIncome', 'transferNote'],
          ['saleTaiwanpay', 'saleTaiwanpayCard', 'saleTaiwanpayBank', 'taiwanpaySaleDiff'],
          ['refundTaiwanpay', 'refundTaiwanpayCard', 'refundTaiwanpayBank', 'refundTaiwanpayDiff'],
          ['saleTaiwanpayCardSystem', 'saleTaiwanpayBankSystem', 'refundTaiwanpayCardSys', 'refundTaiwanpayBankSys'],
          ['taiwanpayFee', 'taiwanpayAmount', 'taiwanpayDiff'],
          ['difference', 'checkoutStaffer.id'],
          ['systemNote'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
